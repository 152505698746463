import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { Link } from "react-router-dom";

import withStyles from "@material-ui/core/styles/withStyles";
import contractStyles from "../../../assets/css/contractStyles.jsx";

import Typography from "@material-ui/core/Typography";
import { InputLabel } from "@material-ui/core";
import CustomButton from "../../../components/CustomButton";
import format from "number-format.js";
import { appConstants } from "../../../constants/app";

const isValidHydrologicalForPayment = (hydrological_uuid) => {
    switch (hydrological_uuid) {
        case "02cb71c9-91b9-dde4-4e62-3addb4fb67e4":
        case "fcfa828b-8410-b60f-caf8-6bb384061a73":
        case "f570bed5-93ff-59c4-770d-cf2468b89056":
        case "6a26fc94-439b-c452-4b2e-7256e21566fc":
        case "0863324d-0c2d-70fc-d2c6-a65c6253d16c":
            return true;
        default:
            return false;
    }
};

const ContractDetail = ({
    classes,
    selected_contract_debt,
    selected_contract_detail,
    selected_contract_id,
    selected_contract_uuid,
    selected_contract_hydrologic_uuid,
    selected_contract_hydrologic_name,
    online_payment_available
}) => {
    return (
        <div className={classes.detail_container}>
            {(selected_contract_detail || selected_contract_detail) && (
                <Fragment>
                    <InputLabel>Titular</InputLabel>
                    <Typography gutterBottom variant="headline">
                        {selected_contract_detail && selected_contract_detail.nombre}
                    </Typography>
                    <InputLabel>Dirección</InputLabel>
                    <Typography gutterBottom variant="headline">
                        {selected_contract_detail && selected_contract_detail.direccionSuministro}
                    </Typography>
                    <InputLabel>Importe total</InputLabel>
                    <Typography gutterBottom variant="headline">
                        {appConstants.CURRENCY}{" "}
                        {selected_contract_debt &&
                            (format("#.##0,##", selected_contract_debt.importeDeuda) || 0)}
                    </Typography>
                    {selected_contract_debt &&
                        selected_contract_debt.importeDeuda > 0 &&
                        online_payment_available &&
                        isValidHydrologicalForPayment(selected_contract_hydrologic_uuid) && (
                            <Link
                                to={{
                                    pathname: "/payment",
                                    state: {
                                        contract_id: selected_contract_id,
                                        contract_uuid: selected_contract_uuid,
                                        contract_hydrologic_name: selected_contract_hydrologic_name,
                                        contract_holder: selected_contract_detail.nombre,
                                        contract_address:
                                            selected_contract_detail.direccionSuministro,
                                        debt: selected_contract_debt.importeDeuda,
                                        payment_reference: selected_contract_debt.referenciaPago
                                    }
                                }}
                            >
                                <CustomButton type="button" fullWidth={false}>
                                    Pagar
                                </CustomButton>
                            </Link>
                        )}
                </Fragment>
            )}
        </div>
    );
};

ContractDetail.propTypes = {
    selected_contract_debt: PropTypes.object,
    selected_contract_detail: PropTypes.object,
    selected_contract_id: PropTypes.string.isRequired,
    selected_contract_uuid: PropTypes.string.isRequired,
    selected_contract_hydrologic_uuid: PropTypes.string.isRequired,
    selected_contract_hydrologic_name: PropTypes.string.isRequired,
    online_payment_available: PropTypes.bool
};

const mapStateToProps = (state) => {
    const {
        selected_contract_debt,
        selected_contract_detail,
        selected_contract_id,
        selected_contract_uuid,
        selected_contract_hydrologic_uuid,
        selected_contract_hydrologic_name,
        online_payment_available
    } = state.contractsReducers;
    return {
        selected_contract_debt,
        selected_contract_detail,
        selected_contract_id,
        selected_contract_uuid,
        selected_contract_hydrologic_uuid,
        selected_contract_hydrologic_name,
        online_payment_available
    };
};

export default connect(mapStateToProps)(withStyles(contractStyles)(ContractDetail));
