import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { getContract } from "../actions";

import withStyles from "@material-ui/core/styles/withStyles";
import contractStyles from "../../../assets/css/contractStyles.jsx";

import { ListItem, ListItemAvatar, Avatar, List, ListItemText } from "@material-ui/core";
import ChevronRight from "@material-ui/icons/ChevronRight";

class ContractList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selected_item: null
        };
    }

    componentDidUpdate(prevProps) {
        if (prevProps.contracts_list.length !== this.props.contracts_list.length) {
            this.setState({ selected_item: null });
        }
    }

    getContract = async (
        event,
        contract_uuid,
        contract_id,
        hydrological_id,
        hydrologic_name,
        index
    ) => {
        const { getContract, resetTabSelection } = this.props;
        resetTabSelection(event);
        this.setState({ selected_item: index });

        await getContract(contract_uuid, contract_id, hydrological_id, hydrologic_name);

        const { selected_contract_available } = this.props;
        if (!selected_contract_available) {
            this.resetSelection();
        }
    };

    resetSelection = () => {
        this.setState({ selected_item: null });
    };

    render() {
        const { contracts_list, image_update, classes } = this.props;
        const { selected_item } = this.state;
        return (
            <div className={classes.container}>
                {contracts_list.length && (
                    <List className={classes.list}>
                        {contracts_list.map((contract, index) => (
                            <ListItem
                                key={index}
                                onClick={(event) =>
                                    this.getContract(
                                        event,
                                        contract.uuid,
                                        contract.claveExterna,
                                        contract.hidrologica,
                                        contract.descripcionHidrologica,
                                        index
                                    )
                                }
                                selected={selected_item === index}
                                className={classes.list_item}
                            >
                                {contract && (
                                    <img src={contract.imagen} className={classes.contract_img} />
                                )}
                                <ListItemText
                                    primary={`Nro. ${contract.claveExterna}`}
                                    secondary={contract.descripcionHidrologica}
                                    primaryTypographyProps={{ variant: "h5" }}
                                    secondaryTypographyProps={{ variant: "subheading" }}
                                />
                                <ChevronRight />
                            </ListItem>
                        ))}
                    </List>
                )}
            </div>
        );
    }
}

ContractList.proptypes = {
    contracts_list: PropTypes.array,
    selected_contract_available: PropTypes.bool,
    resetTabSelection: PropTypes.func
};

const mapStateToProps = (state) => {
    const { contracts_list, selected_contract_available } = state.contractsReducers;
    return { contracts_list, selected_contract_available };
};

const mapDispatchToProps = {
    getContract
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(contractStyles)(ContractList));
