import { billsConstants, appConstants } from "../../constants";
import axios, { setUpAuthorization } from "../../utilities/axiosSetUp";
import { BILLS_PAGE_SIZE } from "../../config";
import { setGetBillsEvent, setDownloadBill } from "../../utilities/analytics";

export const getBillsList =
    (contract_uuid, page, hydrologic_name, contract_id) => async (dispatch) => {
        try {
            dispatch({ type: billsConstants.GET_BILLS_REQUEST });
            setUpAuthorization();
            const response = await axios.get(
                `contratos/${contract_uuid}/facturas?first=${
                    page * BILLS_PAGE_SIZE
                }&pagesize=${BILLS_PAGE_SIZE}&sortby=fechaFactura&sorttype=DESC`
            );
            dispatch({
                type: billsConstants.GET_BILLS_SUCCESS,
                payload: { bills_list: response.data, page }
            });
            setGetBillsEvent(hydrologic_name, contract_id);
        } catch (error) {
            dispatch({ type: billsConstants.GET_BILLS_FAILURE });
            dispatch({ type: appConstants.SHOW_MESSAGE, payload: error.response.data });
        }
    };

export const getBill = (contract_uuid, bill_id, hydrologic_name) => async (dispatch) => {
    try {
        dispatch({ type: billsConstants.GET_BILL_REQUEST });
        setUpAuthorization();
        const response = await axios.get(
            `contratos/${contract_uuid}/facturas/${bill_id}/download`,
            { responseType: "blob" }
        );
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${bill_id}.pdf`);
        document.body.appendChild(link);
        link.click();
        dispatch({ type: billsConstants.GET_BILL_SUCCESS });
        setDownloadBill(hydrologic_name, bill_id);
    } catch (error) {
        if (error.response.status === 404) {
            dispatch({
                type: appConstants.SHOW_MESSAGE,
                payload: {
                    description:
                        "La factura seleccionada no ha sido generada, por favor intente más tarde."
                }
            });
        } else {
            dispatch({ type: appConstants.SHOW_MESSAGE, payload: error.response.data });
        }
        dispatch({ type: billsConstants.GET_BILL_FAILURE });
    }
};
