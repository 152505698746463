import { intakeConstants } from "../../constants";
import { MONTHS } from "../../config";

const initial_state = {
    intake_list: [],
    loading_intake: false,
    chart_data: [],
    chart_max_value: 0
};

export default (state = initial_state, action) => {
    switch (action.type) {
        case intakeConstants.GET_INTAKE_REQUEST:
            return { ...state, loading_intake: true };

        case intakeConstants.GET_INTAKE_SUCCESS:
            const list = action.payload;
            let max = state.chart_max_value;
            if (list.length) {
                let chart_data = [];
                for (const item of list) {
                    let date = new Date(item.fechaDesdePeriodo);
                    chart_data.unshift([MONTHS[date.getMonth()], item.consumoParaFacturar]);
                    if (item.consumoParaFacturar > max) {
                        max = item.consumoParaFacturar;
                    }
                }
                const max_value = max + (max % 10) + 10;
                return {
                    ...state,
                    intake_list: action.payload,
                    loading_intake: false,
                    chart_data: chart_data,
                    chart_max_value: max_value
                };
            }
            return { ...state, loading_intake: false };

        case intakeConstants.GET_INTAKE_FAILURE:
            return { ...state, loading_intake: false };
        case intakeConstants.RESET_INTAKE_LIST:
            return {
                ...state,
                intake_list: [],
                chart_data: [],
                chart_max_value: 0
            };
        default:
            return { ...state };
    }
};
