import ReactGA from "react-ga";
import { DEFAULT_GA_CONFIG } from "../config";

ReactGA.initialize([DEFAULT_GA_CONFIG]);
ReactGA.pageview("test-init-pageview");

const setLoginSuccess = (email) => {
    ReactGA.event({
        category: "Usuario",
        action: "Iniciar sesión",
        label: email
    });
};

const setGetContractEvent = (hydrologic_name, contract_id) => {
    ReactGA.event({
        category: hydrologic_name,
        action: "Consulta deuda",
        label: contract_id
    });
};

const setAddContractEvent = (hydrologic_name, contract_id) => {
    ReactGA.event({
        category: hydrologic_name,
        action: "Crear contrato",
        label: contract_id
    });
};

const setGetBillsEvent = (hydrologic_name, contract_id) => {
    ReactGA.event({
        category: hydrologic_name,
        action: "Consulta de facturas",
        label: contract_id
    });
};

const setDownloadBill = (hydrologic_name, bill_id) => {
    ReactGA.event({
        category: hydrologic_name,
        action: "Descarga de factura",
        label: bill_id
    });
};

const payDebtSuccess = (hydrologic_name, contract_id) => {
    ReactGA.event({
        category: hydrologic_name,
        action: "Pago de contrato exitoso",
        label: contract_id
    });
};

const payDebtFail = (hydrologic_name, contract_id) => {
    ReactGA.event({
        category: hydrologic_name,
        action: "Pago de contrato fallido",
        label: contract_id
    });
};

export {
    setLoginSuccess,
    setGetContractEvent,
    setAddContractEvent,
    setGetBillsEvent,
    setDownloadBill,
    payDebtSuccess,
    payDebtFail
};
