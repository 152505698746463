import React, { Fragment, Component } from "react";
import { connect } from "react-redux";

import compose from "recompose/compose";
import withWidth from "@material-ui/core/withWidth";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import homeStyles from "../../assets/css/homeStyles.jsx";

import {
    Typography,
    Grid,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    DialogContentText,
    IconButton,
    Slide,
    ListItemText,
    ListItem,
    ListItemIcon,
    Paper
} from "@material-ui/core";

import Hidden from "@material-ui/core/Hidden";
import ChevronLeft from "@material-ui/icons/ChevronLeft";

import ContractList from "../Contract/ContractList";
import ContractForm from "../Contract/ContractForm";
import NoContractSelected from "./NoContractSelected";
import EmptyContractList from "./EmptyContractList";

import {
    verifyContract,
    addContract,
    getContractsList,
    deleteContract,
    unselectContract,
    getHydrologicsList,
    getHydrologicalImage,
    clearHydrologicalImage
} from "../Contract/actions";

import CustomButton from "../../components/CustomButton";
import ActionBar from "./ActionBar";
import DetailsTabs from "./DetailsTabs";

const hidrovenImage = require("../../assets/img/hidroven.png");

class Home extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value: 0,
            add_contract_is_open: false,
            confirm_contract_is_open: false,
            delete_contract_is_open: false,
            image_update: false
        };
    }

    componentDidMount() {
        const { getContractsList, getHydrologicsList } = this.props;
        const { image_update } = this.state;
        getContractsList().then(() => {
            this.props.contracts_list.map((contract) => {
                if (!contract.imagen) contract.imagen = hidrovenImage;
            });
            this.setState({ image_update: !image_update });
        });
        getHydrologicsList().then(() => {
            this.props.hydrological_list.map((hidro) => {
                if (!hidro.imagen) hidro.imagen = hidrovenImage;
            });
        });
    }

    componentDidUpdate(prevProps) {
        const { getHydrologicalImage, contracts_list, hydrological_list, hydrological_image } =
            this.props;
        const { image_update } = this.state;

        if (
            prevProps.hydrological_list !== hydrological_list &&
            hydrological_list.length !== hydrological_image.length
        ) {
            this.props.clearHydrologicalImage();
            hydrological_list.forEach((element) => {
                getHydrologicalImage(element.uuid);
            });
        }

        if (
            prevProps.hydrological_image !== hydrological_image &&
            hydrological_list.length === hydrological_image.length
        ) {
            hydrological_image.forEach((element) => {
                let hidro = hydrological_list.find((obj) => obj.uuid == element.uuid);
                if (!element.imagen) hidro.imagen = null;
                hidro.imagen = element.imagen;
                if (contracts_list.length > 0) {
                    contracts_list.map((contract) => {
                        if (contract.hidrologica == element.uuid) {
                            contract.imagen = element.imagen;
                        }
                    });
                }
            });
            this.setState({ image_update: !image_update });
        }

        if (
            contracts_list.length > 0 &&
            hydrological_image.length > 0 &&
            prevProps.contracts_list !== contracts_list
        ) {
            hydrological_image.forEach((element) => {
                contracts_list.map((contract) => {
                    if (!contract.imagen && contract.hidrologica == element.uuid) {
                        contract.imagen = element.imagen;
                    }
                });
            });
        }

        if (hydrological_image.length > 0 && prevProps.hydrological_list !== hydrological_list) {
            hydrological_image.forEach((element) => {
                let hidro = hydrological_list.find((obj) => obj.uuid == element.uuid);
                if (!element.imagen) hidro.imagen = null;
                hidro.imagen = element.imagen;
            });
        }
    }

    componentWillUnmount() {
        this.props.unselectContract();
    }

    handleTabsChange = (event, value) => {
        this.setState({ value });
    };

    handleAddContract = async (hydrological, document_number) => {
        await this.props.verifyContract(
            document_number,
            hydrological.uuid,
            hydrological.descripcion
        );
        if (this.props.contract_exists) {
            this.handleCloseDialog();
            this.setState({ confirm_contract_is_open: true });
        }
    };

    handleCloseDialog = () => {
        this.setState({ add_contract_is_open: !this.state.add_contract_is_open });
    };

    handleCloseAddConfirmation = () => {
        this.setState({ confirm_contract_is_open: false });
    };

    addContract = () => {
        const { addContract, contract_id, hydrologic_id, hydrologic_name, unselectContract } =
            this.props;
        addContract(contract_id, hydrologic_id, hydrologic_name);
        unselectContract();
        this.handleCloseAddConfirmation();
    };

    confirmDeleteContract = () => {
        this.setState({ delete_contract_is_open: true });
    };

    deleteContract = () => {
        const { deleteContract, selected_contract_uuid, selected_contract_id, unselectContract } =
            this.props;
        deleteContract(selected_contract_uuid, selected_contract_id);
        unselectContract();
        this.handleCloseDeleteConfirmation();
    };

    handleCloseDeleteConfirmation = () => {
        this.setState({
            delete_contract_is_open: false,
            delete_contract_uuid: null,
            delete_contract_id: null
        });
    };

    handleMobileBack = () => {
        this.props.unselectContract();
    };

    resetTabSelection = (event) => {
        this.handleTabsChange(event, 0);
    };

    render() {
        const {
            classes,
            width,
            contract_name,
            contract_address,
            selected_contract,
            contracts_list,
            selected_contract_id,
            loading_contract,
            gettingList
        } = this.props;
        const { value, confirm_contract_is_open, delete_contract_is_open, add_contract_is_open } =
            this.state;

        return (
            <Fragment>
                <div className={classes.home_container}>
                    {contracts_list.length ? (
                        <Grid
                            container
                            direction="row"
                            justify="center"
                            className={classes.grid_container}
                        >
                            <Grid item xs={12} md={5} className={classes.test}>
                                {(width === "xs" || width === "sm") && !selected_contract && (
                                    <Grid container>
                                        <Grid item xs={12} md={12} lg={12}>
                                            <ActionBar
                                                selected_contract={selected_contract}
                                                handleCloseDialog={this.handleCloseDialog}
                                                confirmDeleteContract={this.confirmDeleteContract}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={12} lg={12}>
                                            <ContractList
                                                image_update={this.state.image_update}
                                                resetTabSelection={this.resetTabSelection}
                                            />
                                        </Grid>
                                    </Grid>
                                )}
                                {(width === "md" || width === "lg" || width === "xl") && (
                                    <Grid container>
                                        <Grid item xs={12} md={12} lg={12}>
                                            <ActionBar
                                                selected_contract={selected_contract}
                                                handleCloseDialog={this.handleCloseDialog}
                                                confirmDeleteContract={this.confirmDeleteContract}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={12} lg={12}>
                                            <ContractList
                                                image_update={this.state.image_update}
                                                resetTabSelection={this.resetTabSelection}
                                            />
                                        </Grid>
                                    </Grid>
                                )}
                                <Hidden smUp>
                                    <Slide
                                        direction="left"
                                        in={selected_contract}
                                        mountOnEnter
                                        unmountOnExit
                                    >
                                        {selected_contract ? (
                                            <Paper>
                                                <ListItem>
                                                    <ListItemIcon>
                                                        <IconButton
                                                            aria-label="Eliminar"
                                                            onClick={this.handleMobileBack}
                                                        >
                                                            <ChevronLeft />
                                                        </IconButton>
                                                    </ListItemIcon>
                                                    <ListItemText
                                                        primary={`Nro. ${selected_contract_id}`}
                                                        primaryTypographyProps={{ variant: "h5" }}
                                                        secondaryTypographyProps={{
                                                            variant: "subheading"
                                                        }}
                                                    />
                                                </ListItem>
                                                <DetailsTabs
                                                    tabIndex={value}
                                                    handleTabsChange={this.handleTabsChange}
                                                />
                                            </Paper>
                                        ) : (
                                            <NoContractSelected
                                                loading_contract={loading_contract}
                                            />
                                        )}
                                    </Slide>
                                </Hidden>
                            </Grid>
                            <Hidden smDown>
                                <Grid item xs={12} md={7}>
                                    {selected_contract ? (
                                        <DetailsTabs
                                            tabIndex={value}
                                            handleTabsChange={this.handleTabsChange}
                                        />
                                    ) : (
                                        <NoContractSelected loading_contract={loading_contract} />
                                    )}
                                </Grid>
                            </Hidden>
                        </Grid>
                    ) : (
                        <EmptyContractList
                            handleCloseDialog={this.handleCloseDialog}
                            loading_contract_list={gettingList}
                        />
                    )}
                </div>
                <ContractForm
                    image_update={this.state.image_update}
                    is_open={add_contract_is_open}
                    close_dialog={this.handleCloseDialog}
                    add_contract={this.handleAddContract}
                />
                <Dialog
                    open={confirm_contract_is_open}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle>Confirmar contrato</DialogTitle>
                    <DialogContent>
                        <Typography>
                            ¿Está seguro de que desea agregar el contrato con los siguientes datos?
                        </Typography>
                        <Typography>Titular: {contract_name}</Typography>
                        <Typography>Dirección: {contract_address}</Typography>
                        <DialogActions>
                            <CustomButton onClick={this.addContract}>Aceptar</CustomButton>
                            <Button color="secondary" onClick={this.handleCloseAddConfirmation}>
                                Cancelar
                            </Button>
                        </DialogActions>
                    </DialogContent>
                </Dialog>

                <Dialog
                    open={delete_contract_is_open}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle>Confirmar contrato</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            ¿Está seguro de que desea eliminar el contrato Nro.{" "}
                            {selected_contract_id}?
                        </DialogContentText>
                        <DialogActions>
                            <CustomButton onClick={this.deleteContract}>Aceptar</CustomButton>
                            <Button color="secondary" onClick={this.handleCloseDeleteConfirmation}>
                                Cancelar
                            </Button>
                        </DialogActions>
                    </DialogContent>
                </Dialog>
            </Fragment>
        );
    }
}

Home.propTypes = {
    classes: PropTypes.object.isRequired,
    width: PropTypes.string.isRequired,
    contract_exists: PropTypes.bool,
    contract_name: PropTypes.string,
    contract_address: PropTypes.string,
    contract_id: PropTypes.string,
    hydrologic_id: PropTypes.string,
    hydrologic_name: PropTypes.string,
    selected_contract: PropTypes.bool,
    contracts_list: PropTypes.array,
    selected_contract_uuid: PropTypes.string,
    selected_contract_id: PropTypes.string,
    loading_contract: PropTypes.bool.isRequired,
    gettingList: PropTypes.bool.isRequired
};

const mapStateToProps = (state) => {
    const {
        contract_exists,
        contract_name,
        contract_address,
        contract_id,
        hydrologic_id,
        hydrologic_name,
        selected_contract,
        contracts_list,
        hydrological_list,
        hydrological_image,
        selected_contract_uuid,
        selected_contract_id,
        loading_contract,
        gettingList
    } = state.contractsReducers;
    return {
        contract_exists,
        contract_name,
        contract_address,
        contract_id,
        hydrologic_id,
        hydrologic_name,
        selected_contract,
        contracts_list,
        hydrological_list,
        hydrological_image,
        selected_contract_uuid,
        selected_contract_id,
        loading_contract,
        gettingList
    };
};

const mapDispatchToProps = {
    verifyContract,
    addContract,
    getContractsList,
    deleteContract,
    unselectContract,
    getHydrologicsList,
    getHydrologicalImage,
    clearHydrologicalImage
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(compose(withStyles(homeStyles), withWidth())(Home));
