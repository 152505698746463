import React, { Component, Fragment } from "react";
import { history } from "../../../_helpers/history";
import { connect } from "react-redux";
import compose from "recompose/compose";

import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import withWidth from "@material-ui/core/withWidth";
import billsStyles from "../../../assets/css/billsStyles.jsx";
import {
    FormControl,
    TextField,
    FormHelperText,
    Button,
    Typography,
    Stepper,
    Step,
    StepLabel,
    Divider,
    Grid
} from "@material-ui/core";
import MaskedInput from "react-text-mask";
import CustomButton from "../../../components/CustomButton";

import cvc_img from "../../../assets/img/cvv-caption_new.png";
import instapago_banesco from "../../../assets/img/instapago_banesco.png";

import { postPaymentDebt, clearPaymentData } from "../actions";
import PaymentProcessing from "../PaymentProcessing";
import format from "number-format.js";
import { appConstants } from "../../../constants/app";

const TextMaskCardCustom = (props) => {
    const { inputRef, ...other } = props;
    return (
        <MaskedInput
            {...other}
            ref={(ref) => {
                inputRef(ref ? ref.inputElement : null);
            }}
            mask={[
                /\d/,
                /\d/,
                /\d/,
                /\d/,
                " ",
                /\d/,
                /\d/,
                /\d/,
                /\d/,
                " ",
                /\d/,
                /\d/,
                /\d/,
                /\d/,
                " ",
                /\d/,
                /\d/,
                /\d/,
                /\d/
            ]}
            placeholderChar={"\u2000"}
            guide={false}
        />
    );
};

const TextMaskExpirationCustom = (props) => {
    const { inputRef, ...other } = props;
    return (
        <MaskedInput
            {...other}
            ref={(ref) => {
                inputRef(ref ? ref.inputElement : null);
            }}
            mask={[/[0,1]/, /[0-9]/, "/", "2", "0", /\d/, /\d/]}
            placeholderChar={"\u2000"}
            guide={false}
        />
    );
};

const documentMask = (rawValue) => {
    let mask = [];
    if (
        rawValue[0] === "V" ||
        rawValue[0] === "E" ||
        rawValue[0] === "J" ||
        rawValue[0] === "P" ||
        rawValue[0] === "G"
    ) {
        mask = [/[V,E,J,P,G]/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/];
    } else {
        mask = [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/];
    }
    return mask;
};

const TextMaskDocumentNumber = (props) => {
    const { inputRef, ...other } = props;
    return (
        <MaskedInput
            {...other}
            ref={(ref) => {
                inputRef(ref ? ref.inputElement : null);
            }}
            mask={documentMask}
            placeholderChar={"\u2000"}
            guide={false}
        />
    );
};

class PaymentForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            card_number: "",
            expiration_date: "",
            name: "",
            invalid_name: false,
            invalid_expiration: false,
            invalid_card: false,
            empty_name: true,
            empty_card: true,
            empty_expiration: true,
            empty_cvc: true,
            empty_document: true,
            cvc: "",
            document_number: "",
            active_step: 0,
            hidden_card: "",
            confirmed_payment: false
        };
    }

    componentDidMount() {
        if (!this.props.location.state) {
            history.push("/");
        }
    }

    getHiddenCardNumber = () => {
        const trimed_number = this.state.card_number.replace(/\s/g, "");
        return `${trimed_number.substring(0, 4)}****${trimed_number.substring(12, 16)}`;
    };

    handleChange = (e) => {
        const { name, value } = e.target;
        this.setState({ [name]: value });
        switch (name) {
            case "name":
                this.setState({ invalid_name: !/^[a-zA-Zñ]+\s[a-zA-Zñ]+$/.test(value.trim()) });
                this.setState({ empty_name: false });
                break;
            case "document_number":
                this.setState({ empty_document: value.trim() === "" || false });
                break;
            case "card_number":
                this.setState({ invalid_card: !(value.trim().length === 19) });
                this.setState({ empty_card: false });
                break;
            case "expiration_date":
                const current_year = new Date().getFullYear();
                const month_input = parseInt(value.substring(0));
                const year_input = parseInt(value.substring(3));

                this.setState({
                    invalid_expiration: !(
                        month_input >= 1 &&
                        month_input <= 12 &&
                        year_input >= current_year
                    )
                });
                this.setState({ empty_expiration: false });
                break;
            case "cvc":
                this.setState({ empty_cvc: false });
                break;
        }
    };

    handleNextStep = (event) => {
        event.preventDefault();
        const { active_step } = this.state;
        if (active_step === 1) {
            const { postPaymentDebt } = this.props;
            const { name, document_number, card_number, cvc, expiration_date } = this.state;
            const {
                contract_uuid,
                contract_id,
                contract_hydrologic_name,
                payment_reference,
                debt
            } = this.props.location.state;

            this.setState({
                confirmed_payment: true
            });

            postPaymentDebt(
                contract_uuid,
                contract_id,
                debt,
                payment_reference,
                name,
                document_number.replace(/ /g, ""),
                card_number.replace(/ /g, ""),
                cvc,
                expiration_date,
                contract_hydrologic_name
            );
        } else {
            this.setState({ active_step: active_step + 1 });
            this.setState({ hidden_card: this.getHiddenCardNumber() });
        }
    };

    handleCancelPayment = () => {
        history.push("/");
    };

    handleBackStep = () => {
        const { active_step } = this.state;
        this.setState({ active_step: active_step - 1 });
    };

    clearPaymentData = () => {
        this.props.clearPaymentData();
    };

    render() {
        const {
            card_number,
            name,
            invalid_name,
            expiration_date,
            cvc,
            document_number,
            active_step,
            hidden_card,
            empty_name,
            empty_card,
            empty_expiration,
            empty_cvc,
            empty_document,
            invalid_expiration,
            invalid_card
        } = this.state;
        const { classes, width, location, processing_payment, successful_payment, voucher } =
            this.props;

        return (
            <Fragment>
                {!successful_payment && (
                    <div className={classes.steps_container}>
                        {(width === "md" || width === "lg" || width === "xl") && (
                            <Stepper activeStep={active_step}>
                                <Step key={0}>
                                    <StepLabel>Datos de pago</StepLabel>
                                </Step>
                                <Step key={1}>
                                    <StepLabel>Confirmación de datos</StepLabel>
                                </Step>
                            </Stepper>
                        )}
                        {active_step === 0 && (
                            <form className={classes.form_wrapper} autoComplete="off">
                                <FormControl fullWidth={true}>
                                    <TextField
                                        className={classes.formControl}
                                        margin="normal"
                                        name="card_number"
                                        onChange={this.handleChange}
                                        value={card_number}
                                        required
                                        label="Número de tarjeta de crédito"
                                        InputProps={{
                                            inputComponent: TextMaskCardCustom
                                        }}
                                    />
                                    {invalid_card && (
                                        <FormHelperText id="component-error-text4" error>
                                            El número de la tarjeta debe contener 16 números
                                        </FormHelperText>
                                    )}
                                </FormControl>
                                <FormControl>
                                    <TextField
                                        className={classes.small_input}
                                        margin="normal"
                                        name="expiration_date"
                                        onChange={this.handleChange}
                                        value={expiration_date}
                                        required
                                        label="MM/AAAA"
                                        InputProps={{
                                            inputComponent: TextMaskExpirationCustom
                                        }}
                                        error={invalid_expiration}
                                    />
                                    {invalid_expiration && (
                                        <FormHelperText id="component-error-text4" error>
                                            La fecha de expiracion es inválida
                                        </FormHelperText>
                                    )}
                                </FormControl>
                                <img src={cvc_img} className={classes.cvc_img} />
                                <FormControl>
                                    <TextField
                                        className={classes.small_input}
                                        margin="normal"
                                        name="cvc"
                                        onChange={this.handleChange}
                                        value={cvc}
                                        required
                                        type="password"
                                        autoComplete="new-password"
                                        label="CVC"
                                        inputProps={{
                                            maxLength: 3,
                                            inputMode: "numeric"
                                        }}
                                    />
                                </FormControl>
                                <FormControl fullWidth={true}>
                                    <TextField
                                        className={classes.formControl}
                                        margin="normal"
                                        name="name"
                                        onChange={this.handleChange}
                                        value={name}
                                        required
                                        label="Nombre y Apellido"
                                        error={invalid_name}
                                        fullWidth={true}
                                    />
                                    {invalid_name && (
                                        <FormHelperText id="component-error-text4" error>
                                            Nombre y apellido sólo deben tener caracteres
                                            alfabéticos incluyendo la letra ñ y el espacio en blanco
                                            para separarlos
                                        </FormHelperText>
                                    )}
                                </FormControl>
                                <FormControl fullWidth={true}>
                                    <TextField
                                        label="Cédula"
                                        margin="normal"
                                        name="document_number"
                                        onChange={this.handleChange}
                                        value={document_number}
                                        required
                                        InputProps={{
                                            inputComponent: TextMaskDocumentNumber
                                        }}
                                    />
                                </FormControl>
                                <div className={classes.transaction_message}>
                                    <Typography>
                                        Esta transacción será procesada de forma segura gracias a la
                                        plataforma de:
                                    </Typography>
                                    <img
                                        src={instapago_banesco}
                                        className={classes.instapago_banesco}
                                    />
                                </div>
                            </form>
                        )}
                        {active_step === 1 && (
                            <div className={classes.details_wrapper}>
                                <Typography
                                    align="left"
                                    variant="title"
                                    className={classes.details_section}
                                >
                                    Datos del contrato
                                </Typography>
                                <Grid container spacing={16} justify="center">
                                    <Grid item xs={6} className={classes.text_left}>
                                        <Typography
                                            className={classes.details_title}
                                            variant="subheading"
                                            paragraph
                                        >
                                            Nro. Contrato
                                        </Typography>
                                        <Typography
                                            className={classes.details_title}
                                            variant="subheading"
                                            paragraph
                                        >
                                            Importe total
                                        </Typography>
                                        <Typography
                                            className={classes.details_title}
                                            variant="subheading"
                                            paragraph
                                        >
                                            Titular
                                        </Typography>
                                        <Typography
                                            className={classes.details_title}
                                            variant="subheading"
                                            paragraph
                                        >
                                            Dirección
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6} className={classes.text_right}>
                                        <Typography variant="subheading" paragraph>
                                            {location.state.contract_id}
                                        </Typography>
                                        <Typography variant="subheading" paragraph>
                                            {appConstants.CURRENCY}{" "}
                                            {format("#.##0,##", location.state.debt) || 0}
                                        </Typography>
                                        <Typography variant="subheading" paragraph>
                                            {location.state.contract_holder}
                                        </Typography>
                                        <Typography variant="subheading" paragraph>
                                            {location.state.contract_address}
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Divider variant="fullWidth" />
                                <Typography
                                    align="left"
                                    variant="title"
                                    className={classes.details_section}
                                >
                                    Datos de pago
                                </Typography>
                                <Grid container spacing={16} justify="center">
                                    <Grid item xs={6} className={classes.text_left}>
                                        <Typography
                                            className={classes.details_title}
                                            variant="subheading"
                                            paragraph
                                        >
                                            Nro. Tarjeta
                                        </Typography>
                                        <Typography
                                            className={classes.details_title}
                                            variant="subheading"
                                            paragraph
                                        >
                                            Fecha de vencimiento
                                        </Typography>
                                        <Typography
                                            className={classes.details_title}
                                            variant="subheading"
                                            paragraph
                                        >
                                            Nombre y apellido
                                        </Typography>
                                        <Typography
                                            className={classes.details_title}
                                            variant="subheading"
                                            paragraph
                                        >
                                            Cédula o RIF
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6} className={classes.text_right}>
                                        <Typography variant="subheading" paragraph>
                                            {hidden_card}
                                        </Typography>
                                        <Typography variant="subheading" paragraph>
                                            {expiration_date}
                                        </Typography>
                                        <Typography variant="subheading" paragraph>
                                            {name}
                                        </Typography>
                                        <Typography variant="subheading" paragraph>
                                            {document_number}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </div>
                        )}
                        <div className={classes.action_buttons_wrapper}>
                            {active_step === 1 && (
                                <Button
                                    color="secondary"
                                    type="button"
                                    fullWidth={true}
                                    disabled={processing_payment}
                                    onClick={this.handleBackStep}
                                >
                                    Atrás
                                </Button>
                            )}
                            <CustomButton
                                disabled={
                                    empty_cvc ||
                                    empty_document ||
                                    empty_expiration ||
                                    invalid_expiration ||
                                    empty_card ||
                                    invalid_card ||
                                    empty_name ||
                                    invalid_name
                                }
                                type="button"
                                fullWidth={true}
                                onClick={(event) => {
                                    this.handleNextStep(event);
                                }}
                                loading={processing_payment}
                            >
                                {active_step === 0 ? "Siguiente" : "Confirmar y pagar"}
                            </CustomButton>
                            <CustomButton
                                color="secondary"
                                type="button"
                                fullWidth={true}
                                disabled={processing_payment}
                                onClick={this.handleCancelPayment}
                            >
                                Cancelar
                            </CustomButton>
                        </div>
                    </div>
                )}
                {successful_payment && (
                    <PaymentProcessing
                        successful_payment={successful_payment}
                        clearPaymentData={this.clearPaymentData}
                        voucher={voucher}
                    />
                )}
            </Fragment>
        );
    }
}

TextMaskCardCustom.propTypes = {
    inputRef: PropTypes.func.isRequired
};

PaymentForm.propTypes = {
    processing_payment: PropTypes.bool,
    successful_payment: PropTypes.bool,
    classes: PropTypes.object.isRequired,
    width: PropTypes.string.isRequired
};

const mapStateToProps = (state) => {
    const { processing_payment, successful_payment, voucher } = state.paymentsReducers;
    return {
        processing_payment,
        successful_payment,
        voucher
    };
};

const mapDispatchToProps = {
    postPaymentDebt,
    clearPaymentData
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(compose(withStyles(billsStyles), withWidth())(PaymentForm));
