import theme from "./palette.jsx";

const homeStyles = {
    list: {
        width: "100%",
        backgroundColor: theme.palette.background.paper
    },
    tabs: {},
    tab: {
        "&$tabSelected": {
            color: theme.palette.primary.main
        }
    },
    tabIndicator: {
        backgroundColor: theme.palette.primary.main
    },
    tabSelected: {},
    add_contract_icon: {
        paddingRight: "10px"
    },
    home_container: {
        width: "100%",
        margin: "0 auto",
        minHeight: "35em",
        height: "100%"
    },
    details_container: {
        width: "100%",
        height: "100%",
        minHeight: "35em"
    },
    empty_details: {
        textAlign: "center",
        minHeight: "30em",
        marginTop: "20%"
    },
    sectionDesktop: {
        [theme.breakpoints.up("md")]: {
            display: "flex"
        }
    },
    empty_list: {
        textAlign: "center",
        margin: "8em auto"
    },
    empty_list_text: {
        color: "rgb(147, 154, 165)"
    },
    grid_container: {
        height: "100%"
    },
    test: {
        backgroundColor: "whitesmoke"
    }
};

export default homeStyles;
