import theme from "./palette.jsx";

const appStyles = {
    app: {
        height: "100%",
        minHeight: "400px",
        textAlign: "center",
        display: "flex",
        flexDirection: "column",
        justifyContent: "stretch",
        backgroundColor: theme.palette.background.paper
    },
    appbar: {
        backgroundColor: theme.palette.primary.main
    },
    logo: {
        width: "5em",
        padding: "5px 10px 5px 0"
    },
    grow: {
        flexGrow: 1
    },
    linear_progress: {
        height: "2px",
        width: "100%",
        position: "static",
        top: "65px"
    }
};

export default appStyles;
