import { createMuiTheme } from "@material-ui/core/styles";

const theme = createMuiTheme({
    palette: {
        primary: {
            light: "#60edf7",
            main: "#00bac4",
            dark: "#008994",
            contrastText: "#fff"
        },
        secondary: {
            light: "#5df8c7",
            main: "#00c496",
            dark: "#009268",
            contrastText: "#fff"
        }
    },
    typography: {
        useNextVariants: true
    }
});

export default theme;
