import React from "react";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import classNames from "classnames";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core";

const styles = {
    root: {
        margin: "20px 10px 20px 0px",
        padding: "0 20px"
    },
    default: {
        color: "#EEEEEE",
        backgroundImage: "linear-gradient(90deg, #00C496 , #00BAC4)",
        "& span a": {
            color: "#EEEEEE"
        }
    },
    button_progress: {
        position: "absolute"
    }
};

const CustomButton = (props) => {
    const { classes, children, className, loading, ...other } = props;
    return (
        <Button
            {...other}
            className={classNames(classes.root, className, other.color || classes.default)}
            disabled={loading || other.disabled}
        >
            {loading && <CircularProgress size={24} className={classes.button_progress} />}
            {children}
        </Button>
    );
};

CustomButton.propTypes = {
    children: PropTypes.node.isRequired,
    classes: PropTypes.object.isRequired,
    className: PropTypes.string,
    loading: PropTypes.bool
};

CustomButton.defaultPropTypes = {
    loading: false
};

export default withStyles(styles)(CustomButton);
