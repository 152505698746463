import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { getBillsList, getBill } from "./actions";
import Pagination from "../../components/Pagination";

import withStyles from "@material-ui/core/styles/withStyles";
import billsStyles from "../../assets/css/billsStyles.jsx";
import format from "number-format.js";
import { appConstants } from "../../constants/app";

import {
    Typography,
    CircularProgress,
    List,
    ListItem,
    ListItemText,
    ListItemSecondaryAction,
    IconButton
} from "@material-ui/core";
import GetApp from "@material-ui/icons/GetApp";

class BillsList extends React.Component {
    componentDidMount() {
        const {
            selected_contract_uuid,
            bills_list,
            selected_contract_hydrologic_name,
            selected_contract_id,
            getBillsList,
            page
        } = this.props;
        if (!bills_list.length) {
            getBillsList(
                selected_contract_uuid,
                page,
                selected_contract_hydrologic_name,
                selected_contract_id
            );
        }
    }

    componentDidUpdate(prevProps) {
        const { selected_contract_uuid, getBillsList, page } = this.props;
        if (prevProps.selected_contract_uuid !== selected_contract_uuid) {
            getBillsList(selected_contract_uuid, page);
        }
    }

    getBill = (bill_id) => {
        const {
            selected_contract_uuid,
            selected_contract_hydrologic_name,
            selected_contract_id,
            getBill
        } = this.props;
        getBill(
            selected_contract_uuid,
            bill_id,
            selected_contract_hydrologic_name,
            selected_contract_id
        );
    };

    handlePagination = async (moveForward) => {
        let { page } = this.props;
        const new_page = moveForward ? page + 1 : page - 1;
        const { selected_contract_uuid, getBillsList, final_bills_page } = this.props;

        await getBillsList(selected_contract_uuid, new_page);
    };

    render() {
        const { classes, bills_list, selected_bill, final_bills_page, loading_bills, page } =
            this.props;

        if (loading_bills) {
            return <CircularProgress className={classes.empty_details} />;
        }

        return (
            <React.Fragment>
                <div className={classes.bills_list}>
                    {bills_list.length ? (
                        <List>
                            {bills_list.map((bill, index) => (
                                <ListItem
                                    key={bill.idFactura}
                                    button
                                    className={classes.bill_item}
                                    selected={selected_bill === index}
                                    onClick={(event) => this.getBill(bill.idFactura)}
                                >
                                    <ListItemText
                                        className={classes.bill_item_text}
                                        secondary={new Intl.DateTimeFormat("es", {
                                            year: "numeric"
                                        }).format(new Date(bill.fechaFactura))}
                                        primary={new Intl.DateTimeFormat("es", {
                                            month: "short"
                                        }).format(new Date(bill.fechaFactura))}
                                        primaryTypographyProps={{ variant: "h6" }}
                                        secondaryTypographyProps={{ variant: "subheading" }}
                                    />
                                    <ListItemText
                                        className={classes.bill_item_text}
                                        secondary={`Nro. ${bill.claveExternaFactura}`}
                                        primary={`${appConstants.CURRENCY} ${
                                            format("#.##0,##", bill.importeTotal) || 0
                                        }`}
                                        primaryTypographyProps={{ variant: "h5" }}
                                        secondaryTypographyProps={{ variant: "subheading" }}
                                    />
                                    <ListItemSecondaryAction>
                                        <IconButton
                                            aria-label="Descargar"
                                            className={classes.download_icon}
                                            onClick={(event) => this.getBill(bill.idFactura)}
                                        >
                                            <GetApp />
                                        </IconButton>
                                    </ListItemSecondaryAction>
                                </ListItem>
                            ))}
                        </List>
                    ) : (
                        <div className={classes.empty_details}>
                            <Typography variant="subheading">
                                No se encuentran facturas asociadas al contrato seleccionado.
                            </Typography>
                        </div>
                    )}
                </div>
                <Pagination
                    page={page}
                    final_page={final_bills_page}
                    callToPagination={this.handlePagination}
                />
            </React.Fragment>
        );
    }
}

BillsList.propTypes = {
    bills_list: PropTypes.array,
    final_bills_page: PropTypes.bool,
    loading_bills: PropTypes.bool,
    page: PropTypes.number.isRequired
};

const mapStateToProps = (state) => {
    const { bills_list, final_bills_page, loading_bills, page } = state.billsReducers;
    const { selected_contract_uuid, selected_contract_hydrologic_name, selected_contract_id } =
        state.contractsReducers;
    return {
        bills_list,
        final_bills_page,
        loading_bills,
        selected_contract_uuid,
        selected_contract_hydrologic_name,
        selected_contract_id,
        page
    };
};

const mapDispatchToProps = {
    getBillsList,
    getBill
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(billsStyles)(BillsList));
