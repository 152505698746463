import React, { Fragment } from "react";
import { DescriptionOutlined, NoteAdd } from "@material-ui/icons";
import { Typography, CircularProgress } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import homeStyles from "../../../assets/css/homeStyles.jsx";
import CustomButton from "../../../components/CustomButton";

const EmptyContractList = ({ classes, handleCloseDialog, loading_contract_list }) => {
    return (
        <div className={classes.empty_list}>
            {loading_contract_list ? (
                <CircularProgress className={classes.details_loader} />
            ) : (
                <Fragment>
                    <div>
                        <DescriptionOutlined
                            style={{ fill: "rgb(117, 117, 117)", fontSize: "8em" }}
                        />
                    </div>
                    <CustomButton type="button" fullWidth={false} onClick={handleCloseDialog}>
                        <NoteAdd className={classes.add_contract_icon} />
                        Agregar contrato
                    </CustomButton>
                    <Typography className={classes.empty_list_text}>
                        ¡Empieza agregando contratos!
                    </Typography>
                </Fragment>
            )}
        </div>
    );
};

export default withStyles(homeStyles)(EmptyContractList);
