import axios from "axios";

import {
    API_BASE_URL,
    API_HEADER_ACCESS_KEY,
    API_HEADER_ACCESS_VALUE,
    REQUEST_TIMEOUT
} from "../config";

export const cancelToken = axios.CancelToken.source();

const instance = axios.create({
    baseURL: API_BASE_URL,
    headers: {
        [API_HEADER_ACCESS_KEY]: API_HEADER_ACCESS_VALUE,
        ["Content-Type"]: "application/json"
    },
    timeout: REQUEST_TIMEOUT,
    cancelToken: cancelToken.token
});

export default instance;

export const setUpAuthorization = () => {
    const user = JSON.parse(localStorage.getItem("user"));
    const token = JSON.parse(localStorage.getItem("token"));
    instance.defaults.headers.common.Authorization = `Bearer ${user}:${token}`;
};
