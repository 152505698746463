import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

const styles = (theme) => ({
    close: {
        padding: theme.spacing.unit / 2
    }
});

class Alert extends React.Component {
    queue = [];
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            message: {},
            queue: []
        };
    }

    componentDidUpdate(prev_props) {
        if (this.props.message !== prev_props.message && this.props.message !== null) {
            this.handleClick(this.props.message);
        }
    }

    handleClick = (message) => {
        this.queue.push({
            message,
            key: new Date().getTime()
        });

        if (this.state.open) {
            this.setState({ open: false });
        } else {
            this.processQueue();
        }
    };

    processQueue = () => {
        if (this.queue.length > 0) {
            this.setState({
                message: this.queue.shift(),
                open: true
            });
        }
    };

    handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        this.setState({ open: false });
        this.props.clearMessage();
    };

    handleExited = () => {
        this.processQueue();
    };

    render() {
        const { classes } = this.props;
        const { message } = this.state;

        return (
            <Snackbar
                key={message.key}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "center"
                }}
                open={this.state.open}
                autoHideDuration={6000}
                onClose={this.handleClose}
                onExited={this.handleExited}
                ContentProps={{
                    "aria-describedby": "message-id"
                }}
                message={<span id="message-id">{message.message}</span>}
                action={[
                    <IconButton
                        key="close"
                        aria-label="Close"
                        color="inherit"
                        className={classes.close}
                        onClick={this.handleClose}
                    >
                        <CloseIcon />
                    </IconButton>
                ]}
            />
        );
    }
}

export default withStyles(styles)(Alert);
