import React, { Fragment } from "react";
import { connect } from "react-redux";

import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import contractStyles from "../../../assets/css/contractStyles.jsx";
import { cancelAddContractRequest } from "../actions";

import Typography from "@material-ui/core/Typography";
import {
    Grid,
    FormHelperText,
    FormControl,
    TextField,
    InputLabel,
    ListItem,
    DialogContent,
    DialogActions,
    DialogTitle,
    Dialog,
    Button
} from "@material-ui/core";

import CustomButton from "../../../components/CustomButton";

class ContractForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selected_item: null,
            document_number: "",
            invalid_document: false,
            empty_hydro: false,
            is_open: props.is_open
        };
    }

    componentDidMount() {}

    handleChangeDocumentNumber = (event) => {
        const { name, value } = event.target;
        this.setState({
            [name]: value,
            invalid_document: false
        });
    };

    handleSelectHydro = (hydrological) => {
        this.setState({
            selected_item: hydrological,
            empty_hydro: false
        });
    };

    handleSubmit = () => {
        const { selected_item, document_number } = this.state;
        if (document_number === "") {
            this.setState({ invalid_document: true });
        }
        if (!selected_item) {
            this.setState({ empty_hydro: true });
        }
        this.props.add_contract(selected_item, document_number);
    };

    handleCancelButton = () => {
        if (this.props.is_verifying) {
            this.props.cancelAddContractRequest();
        }
        this.props.close_dialog();
    };

    clearForm = () => {
        this.setState({
            selected_item: null,
            document_number: "",
            invalid_document: false,
            empty_hydro: false
        });
    };

    render() {
        const { classes, hydrological_list, is_open, is_verifying, image_update } = this.props;
        const { selected_item, document_number, invalid_document, empty_hydro } = this.state;
        return (
            <Dialog
                open={is_open}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth="md"
                onExited={this.clearForm}
            >
                <DialogTitle id="alert-dialog-title">{"Agregar contrato"}</DialogTitle>
                <DialogContent>
                    <FormControl fullWidth={true}>
                        <InputLabel>Seleccione la hidrológica asociada *</InputLabel>
                        <Grid container className={classes.hydro_container}>
                            {hydrological_list &&
                                hydrological_list.map((hydro) => (
                                    <Grid item md={3} key={hydro.uuid}>
                                        <Typography
                                            variant="subheading"
                                            align="center"
                                            className={
                                                !hydro.enServicio
                                                    ? classes.hydro_disabled_label
                                                    : ""
                                            }
                                        >
                                            {hydro.descripcion}
                                        </Typography>
                                        <ListItem
                                            button
                                            selected={
                                                selected_item && selected_item.uuid === hydro.uuid
                                            }
                                            className={classes.hydro_button}
                                            onClick={(event) => this.handleSelectHydro(hydro)}
                                            disabled={!hydro.enServicio || is_verifying}
                                        >
                                            {hydro.uuid && (
                                                <img
                                                    src={hydro.imagen}
                                                    className={classes.hydro_item}
                                                />
                                            )}
                                        </ListItem>
                                    </Grid>
                                ))}
                        </Grid>
                        {empty_hydro && (
                            <FormHelperText id="component-error-text4" error>
                                Debe seleccionar una hidrológica disponible
                            </FormHelperText>
                        )}
                    </FormControl>
                    <FormControl fullWidth={true}>
                        <TextField
                            label="Número de contrato"
                            type="text"
                            margin="normal"
                            name="document_number"
                            onChange={this.handleChangeDocumentNumber}
                            value={document_number}
                            required
                            autoFocus
                            error={invalid_document}
                            disabled={is_verifying}
                        />
                        {invalid_document && (
                            <FormHelperText id="component-error-text4" error>
                                Debe introducir un número de contrato
                            </FormHelperText>
                        )}
                    </FormControl>
                    <DialogActions>
                        <CustomButton
                            onClick={this.handleSubmit}
                            autoFocus
                            loading={is_verifying}
                            type="submit"
                        >
                            Aceptar
                        </CustomButton>
                        <Button onClick={this.handleCancelButton} color="secondary">
                            Cancelar
                        </Button>
                    </DialogActions>
                </DialogContent>
            </Dialog>
        );
    }
}

ContractForm.propTypes = {
    classes: PropTypes.object.isRequired,
    hydrological_list: PropTypes.array,
    is_open: PropTypes.bool.isRequired,
    close_dialog: PropTypes.func.isRequired,
    add_contract: PropTypes.func.isRequired,
    is_verifying: PropTypes.bool
};

const mapStateToProps = (state) => {
    const { hydrological_list, is_verifying } = state.contractsReducers;
    return { hydrological_list, is_verifying };
};

const mapDispatchToProps = {
    cancelAddContractRequest
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(contractStyles)(ContractForm));
