import { intakeConstants, appConstants } from "../../constants";
import axios, { setUpAuthorization } from "../../utilities/axiosSetUp";

export const getIntakeList = (contrato_uuid) => async (dispatch) => {
    try {
        dispatch({ type: intakeConstants.GET_INTAKE_REQUEST });
        setUpAuthorization();
        const response = await axios.get(`contratos/${contrato_uuid}/consumos/?first=0&pagesize=6`);
        dispatch({ type: intakeConstants.GET_INTAKE_SUCCESS, payload: response.data });
    } catch (error) {
        dispatch({ type: intakeConstants.GET_INTAKE_FAILURE });
        dispatch({ type: appConstants.SHOW_MESSAGE, payload: error.response.data });
    }
};
