import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { history } from "../../_helpers/history";

import withStyles from "@material-ui/core/styles/withStyles";
import loginStyles from "../../assets/css/authStyles.jsx";

import { getUser, editUser, changePassword } from "../User/actions";
import {
    Divider,
    Typography,
    Input,
    InputLabel,
    FormControl,
    InputBase,
    FormHelperText,
    CircularProgress,
    Grid
} from "@material-ui/core";
import CustomButton from "../../components/CustomButton";

class UserComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            read_only: true,
            user_name: "",
            user_surname: "",
            user_document: "",
            user_phone: "",
            user_email: "",
            user_new_password: "",
            user_confirm_password: "",
            invalidPassword: false,
            invalidPasswordConfirmation: false
        };
    }

    componentDidMount() {
        const { getUser } = this.props;
        // TODO: Access to user_id from the redux store
        const user_id = JSON.parse(localStorage.user);
        if (user_id) {
            getUser(user_id);
        } else {
            history.push("/");
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const { user_name, user_surname, user_document, user_phone, user_email } = this.props;
        if (user_name && user_name !== prevState.user_name) {
            this.setState({
                user_name: user_name,
                user_surname: user_surname,
                user_document: user_document,
                user_phone: user_phone,
                user_email: user_email
            });
        }
    }

    handleEditProfile = () => {
        this.setState({ read_only: !this.state.read_only });
    };

    handleInputChange = (name) => (event) => {
        const value = event.target.value;
        this.setState({ [name]: value });
        if (name === "user_new_password") {
            this.setState({ invalidPassword: value.length < 8 });
        }
        if (name === "user_new_password" && this.state.user_confirm_password.length) {
            this.setState({
                invalidPasswordConfirmation: this.state.user_confirm_password !== value
            });
        }
        if (name === "user_confirm_password") {
            this.setState({ invalidPasswordConfirmation: this.state.user_new_password !== value });
        }
    };

    handleSaveProfile = () => {
        const { editUser, user_id, user_version } = this.props;
        const { user_name, user_surname, user_document, user_phone } = this.state;
        editUser(user_id, user_name, user_surname, user_document, user_phone, user_version);
        this.handleEditProfile();
    };

    handleChangePassword = () => {
        const { changePassword, user_id, user_version } = this.props;
        const { user_new_password } = this.state;
        changePassword(user_id, user_new_password, user_version);
    };

    render() {
        const {
            read_only,
            user_name,
            user_surname,
            user_document,
            user_phone,
            user_email,
            user_new_password,
            user_confirm_password,
            invalidPassword,
            invalidPasswordConfirmation
        } = this.state;
        const { classes, loading_user } = this.props;

        if (loading_user) {
            return <CircularProgress className={classes.circular_progress} />;
        }

        return (
            <Grid container justify="space-evenly" spacing={24} direction="column">
                <Grid item>
                    <Typography variant="title" className={classes.marginExt} align="center">
                        Información personal
                    </Typography>
                    <Divider variant="middle" />
                    <form className={classes.user_form_container} noValidate autoComplete="off">
                        <FormControl fullWidth={true}>
                            <InputLabel>Nombre</InputLabel>
                            {read_only && (
                                <InputBase
                                    className={classes.margin}
                                    value={user_name}
                                    inputProps={{ readOnly: true }}
                                />
                            )}
                            {!read_only && (
                                <Input
                                    value={user_name}
                                    onChange={this.handleInputChange("user_name")}
                                />
                            )}
                        </FormControl>
                        <FormControl fullWidth={true}>
                            <InputLabel>Apellido</InputLabel>
                            {read_only && (
                                <InputBase
                                    className={classes.margin}
                                    value={user_surname}
                                    inputProps={{ readOnly: true }}
                                />
                            )}
                            {!read_only && (
                                <Input
                                    value={user_surname}
                                    onChange={this.handleInputChange("user_surname")}
                                />
                            )}
                        </FormControl>
                        <FormControl fullWidth={true}>
                            <InputLabel>Número de documento</InputLabel>
                            {read_only && (
                                <InputBase
                                    className={classes.margin}
                                    value={user_document}
                                    inputProps={{ readOnly: true }}
                                />
                            )}
                            {!read_only && (
                                <Input
                                    value={user_document}
                                    onChange={this.handleInputChange("user_document")}
                                />
                            )}
                        </FormControl>
                        <FormControl fullWidth={true}>
                            <InputLabel>Teléfono</InputLabel>
                            {read_only && (
                                <InputBase
                                    className={classes.margin}
                                    value={user_phone}
                                    inputProps={{ readOnly: true }}
                                />
                            )}
                            {!read_only && (
                                <Input
                                    value={user_phone}
                                    onChange={this.handleInputChange("user_phone")}
                                />
                            )}
                        </FormControl>
                        <FormControl fullWidth={true}>
                            <InputLabel>Email</InputLabel>

                            <InputBase
                                className={classes.margin}
                                value={user_email}
                                inputProps={{ readOnly: true }}
                            />
                        </FormControl>
                        {read_only && (
                            <CustomButton type="button" onClick={this.handleEditProfile}>
                                Editar perfil
                            </CustomButton>
                        )}
                        {!read_only && (
                            <CustomButton type="button" onClick={this.handleSaveProfile}>
                                Guardar cambios
                            </CustomButton>
                        )}
                        {!read_only && (
                            <CustomButton
                                type="button"
                                color="secondary"
                                onClick={this.handleEditProfile}
                            >
                                Cancelar
                            </CustomButton>
                        )}
                    </form>
                </Grid>
                <Grid item>
                    <Typography variant="title" className={classes.marginExt} align="center">
                        Contraseña
                    </Typography>
                    <Divider variant="middle" />
                    <form className={classes.user_form_container} noValidate autoComplete="off">
                        <FormControl fullWidth={true}>
                            <InputLabel>Nueva contraseña</InputLabel>
                            <Input
                                value={user_new_password}
                                onChange={this.handleInputChange("user_new_password")}
                                type="password"
                                autoComplete="off"
                            />
                            {invalidPassword && (
                                <FormHelperText id="component-error-text1" error>
                                    La contraseña debe tener mínimo 8 caracteres
                                </FormHelperText>
                            )}
                        </FormControl>
                        <FormControl fullWidth={true}>
                            <InputLabel>Confirmar nueva contraseña</InputLabel>
                            <Input
                                value={user_confirm_password}
                                onChange={this.handleInputChange("user_confirm_password")}
                                type="password"
                                autoComplete="off"
                            />
                            {invalidPasswordConfirmation && (
                                <FormHelperText id="component-error-text2" error>
                                    La contraseña debe coincidir
                                </FormHelperText>
                            )}
                        </FormControl>
                        <CustomButton
                            type="button"
                            onClick={this.handleChangePassword}
                            disabled={
                                user_new_password === "" ||
                                user_confirm_password === "" ||
                                invalidPassword ||
                                invalidPasswordConfirmation
                            }
                        >
                            Cambiar contraseña
                        </CustomButton>
                    </form>
                </Grid>
            </Grid>
        );
    }
}

UserComponent.propTypes = {
    user_id: PropTypes.string.isRequired,
    user_name: PropTypes.string,
    user_surname: PropTypes.string,
    user_document: PropTypes.string,
    user_phone: PropTypes.string,
    user_email: PropTypes.string,
    user_version: PropTypes.number,
    loading_user: PropTypes.bool.isRequired
};

const mapStateToProps = (state) => {
    const {
        user_id,
        user_name,
        user_surname,
        user_document,
        user_phone,
        user_email,
        user_version,
        loading_user
    } = state.userReducers;
    return {
        user_id,
        user_name,
        user_surname,
        user_document,
        user_phone,
        user_email,
        user_version,
        loading_user
    };
};

const mapDispatchToProps = {
    getUser,
    editUser,
    changePassword
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(loginStyles)(UserComponent));
