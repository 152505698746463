import {
    contractsConstants,
    appConstants,
    billsConstants,
    paymentsConstants,
    intakeConstants
} from "../../constants";
import axios, { setUpAuthorization, cancelToken } from "../../utilities/axiosSetUp";
import { setGetContractEvent, setAddContractEvent } from "../../utilities/analytics";

export const getContractsList = () => async (dispatch) => {
    try {
        setUpAuthorization();
        dispatch({ type: contractsConstants.GET_CONTRACTS_LIST_REQUEST });
        const response = await axios.get("/contratos/");
        dispatch({ type: contractsConstants.GET_CONTRACTS_LIST_SUCCESS, payload: response.data });
    } catch (error) {
        dispatch({ type: appConstants.SHOW_MESSAGE, payload: error.response.data });
        dispatch({ type: contractsConstants.GET_CONTRACTS_LIST_FAILURE });
    }
};

const getDebt = (contract_uuid) => {
    return axios.get(`/contratos/${contract_uuid}/consultadeuda`);
};

const getDetail = (contract_id, hydrologic) => {
    return axios.post(`/contratos/datoscontrato`, {
        hidrologica: hydrologic,
        claveExterna: contract_id
    });
};

export const getHydrologic = (hydrologic, hydrologic_name) => async (dispatch) => {
    try {
        setUpAuthorization();
        dispatch({ type: contractsConstants.GET_CONTRACT_RESET });
        dispatch({ type: contractsConstants.GET_HYDROLOGIC_REQUEST });
        const response = await axios.get(`/hidrologicas/${hydrologic}`);
        dispatch({ type: contractsConstants.GET_HYDROLOGIC_SUCCESS, payload: response.data });
    } catch (error) {
        dispatch({ type: contractsConstants.GET_HYDROLOGIC_FAILURE });
        dispatch({
            type: appConstants.SHOW_MESSAGE,
            payload: {
                description: `Los servicios de la hidrológica ${hydrologic_name} no se encuentran disponibles en este momento, por favor inténtalo más tarde.`
            }
        });
    }
};

export const getHydrologicsList = () => async (dispatch) => {
    try {
        setUpAuthorization();
        dispatch({ type: contractsConstants.GET_HYDROLOGIC_LIST_REQUEST });
        const response = await axios.get("/hidrologicas");
        dispatch({ type: contractsConstants.GET_HYDROLOGIC_LIST_SUCCESS, payload: response.data });
    } catch (error) {
        dispatch({ type: contractsConstants.GET_HYDROLOGIC_LIST_FAILURE });
        dispatch({
            type: appConstants.SHOW_MESSAGE,
            payload: {
                description: `No se logró obtener la lista de hidrológicas, por favor intenta más tarde.`,
                error_code: error.response.status
            }
        });
    }
};

export const getHydrologicalImage = (hydrologic) => async (dispatch) => {
    try {
        setUpAuthorization();
        dispatch({ type: contractsConstants.GET_HYDROLOGIC_IMAGE_REQUEST });
        const response = await axios.get(`/hidrologicas/${hydrologic}/imagen`, {
            responseType: "blob"
        });
        const src = URL.createObjectURL(response.data);
        let myResponse = {
            imagen: src,
            uuid: hydrologic
        };
        dispatch({ type: contractsConstants.GET_HYDROLOGIC_IMAGE_SUCCESS, payload: myResponse });
    } catch (error) {
        dispatch({ type: contractsConstants.GET_HYDROLOGIC_IMAGE_FAILURE });
        dispatch({
            type: appConstants.SHOW_MESSAGE,
            payload: {
                description: `No se logró obtener la lista de hidrológicas, por favor intenta más tarde.`,
                error_code: error.response.status
            }
        });
    }
};

export const clearHydrologicalImage = () => (dispatch) => {
    dispatch({ type: contractsConstants.GET_HYDROLOGIC_IMAGE_RESET });
};

export const getContract =
    (contract_uuid, contract_id, hydrologic, hydrologic_name) => async (dispatch) => {
        try {
            setUpAuthorization();
            dispatch({ type: contractsConstants.GET_CONTRACT_RESET });
            dispatch({ type: contractsConstants.GET_CONTRACT_REQUEST });
            dispatch({ type: contractsConstants.GET_HYDROLOGIC_REQUEST });
            const response = await axios.get(`/hidrologicas/${hydrologic}`);
            dispatch({ type: contractsConstants.GET_HYDROLOGIC_SUCCESS, payload: response.data });

            if (response.data.enServicio) {
                const [debt, detail] = await Promise.all([
                    getDebt(contract_uuid),
                    getDetail(contract_id, hydrologic)
                ]);
                dispatch({
                    type: contractsConstants.GET_CONTRACT_SUCCESS,
                    payload: {
                        debt: debt.data,
                        detail: detail.data,
                        contract_id,
                        hydrologic_uuid: hydrologic,
                        hydrologic_name,
                        contract_uuid
                    }
                }); // { debt: debt.data, detail: detail.data, contract_id}
                dispatch({ type: billsConstants.RESET_BILLS_LIST });
                dispatch({ type: paymentsConstants.RESET_PAYMENTS_LIST });
                dispatch({ type: intakeConstants.RESET_INTAKE_LIST });
                setGetContractEvent(hydrologic_name, contract_id);
            } else {
                dispatch({
                    type: appConstants.SHOW_MESSAGE,
                    payload: {
                        description: `Los servicios de la hidrológica ${hydrologic_name} no se encuentran disponibles en este momento, por favor inténtalo más tarde.`
                    }
                });
                dispatch({ type: contractsConstants.GET_CONTRACT_FAILURE });
            }
        } catch (error) {
            dispatch({
                type: appConstants.SHOW_MESSAGE,
                payload: {
                    description: `La información del contrato Nro. ${contract_id} no se encuentra disponible, por favor intente más tarde.`
                }
            });
            dispatch({ type: contractsConstants.GET_CONTRACT_FAILURE });
        }
    };

export const verifyContract = (contract_id, hydrologic_id, hydrologic_name) => async (dispatch) => {
    try {
        setUpAuthorization();
        dispatch({ type: contractsConstants.CONFIRM_CONTRACT_REQUEST });
        const response = await getDetail(contract_id, hydrologic_id);
        dispatch({
            type: contractsConstants.CONFIRM_CONTRACT_SUCCESS,
            payload: response.data,
            contract_id,
            hydrologic_id,
            hydrologic_name
        });
    } catch (error) {
        if (error.response && error.response.status === 404) {
            dispatch({
                type: appConstants.SHOW_MESSAGE,
                payload: {
                    description: `El contrato Nro. ${contract_id} es incorreto o no existe.`
                }
            });
        } else {
            dispatch({
                type: appConstants.SHOW_MESSAGE,
                payload: {
                    description: `La información del contrato Nro. ${contract_id} no se encuentra disponible, por favor intente más tarde.`
                }
            });
        }
        dispatch({ type: contractsConstants.CONFIRM_CONTRACT_FAILURE });
    }
};

export const addContract = (contract_id, hydrologic_id, hydrologic_name) => async (dispatch) => {
    try {
        setUpAuthorization();
        dispatch({ type: contractsConstants.ADD_CONTRACT_REQUEST });
        const response = await axios.post("/contratos/", {
            hidrologica: hydrologic_id,
            claveExterna: contract_id
        });
        dispatch({ type: contractsConstants.ADD_CONTRACT_SUCCESS, payload: response.data });
        setAddContractEvent(hydrologic_name, contract_id);
        dispatch({
            type: appConstants.SHOW_MESSAGE,
            payload: { description: `El contrato Nro. ${contract_id} se agregó exitosamente.` }
        });
    } catch (error) {
        dispatch({
            type: appConstants.SHOW_MESSAGE,
            payload: {
                description: `El contrato Nro. ${contract_id} no se pudo agregar exitosamente, por favor intente más tarde.`
            }
        });
        dispatch({ type: contractsConstants.ADD_CONTRACT_FAILURE });
    }
};

export const deleteContract = (contract_uuid, contract_id) => async (dispatch) => {
    try {
        setUpAuthorization();
        dispatch({ type: contractsConstants.DELETE_CONTRACT_REQUEST });
        await axios.delete(`/contratos/${contract_uuid}`);
        dispatch({ type: contractsConstants.DELETE_CONTRACT_SUCCESS, payload: { contract_id } });
        dispatch({
            type: appConstants.SHOW_MESSAGE,
            payload: {
                description: `El contrato Nro. ${contract_id} se ha eliminado exitosamente.`
            }
        });
    } catch (error) {
        dispatch({ type: contractsConstants.DELETE_CONTRACT_FAILURE });
        dispatch({
            type: appConstants.SHOW_MESSAGE,
            payload: {
                description: `El contrato Nro. ${contract_id} no se pudo eliminar exitosamente, por favor intente más tarde.`
            }
        });
    }
};

export const unselectContract = () => (dispatch) => {
    dispatch({ type: contractsConstants.GET_CONTRACT_RESET });
};

export const cancelAddContractRequest = () => async (dispatch) => {
    try {
        cancelToken.cancel("Operación cancelada por el usuario.");
    } catch (error) {}
};
