export const contractsConstants = {
    GET_CONTRACTS_LIST_REQUEST: "GET_CONTRACTS_LIST_REQUEST",
    GET_CONTRACTS_LIST_SUCCESS: "GET_CONTRACTS_LIST_SUCCESS",
    GET_CONTRACTS_LIST_FAILURE: "GET_CONTRACTS_LIST_FAILURE",
    GET_CONTRACT_RESET: "GET_CONTRACT_RESET",
    GET_CONTRACT_SUCCESS: "GET_CONTRACT_SUCCESS",
    GET_CONTRACT_FAILURE: "GET_CONTRACT_FAILURE",
    GET_CONTRACT_REQUEST: "GET_CONTRACT_REQUEST",
    GET_HYDROLOGIC_REQUEST: "GET_HYDROLOGIC_REQUEST",
    GET_HYDROLOGIC_SUCCESS: "GET_HYDROLOGIC_SUCCESS",
    GET_HYDROLOGIC_FAILURE: "GET_HYDROLOGIC_FAILURE",
    GET_HYDROLOGIC_LIST_REQUEST: "GET_HYDROLOGIC_LIST_REQUEST",
    GET_HYDROLOGIC_LIST_SUCCESS: "GET_HYDROLOGIC_LIST_SUCCESS",
    GET_HYDROLOGIC_LIST_FAILURE: "GET_HYDROLOGIC_LIST_FAILURE",
    GET_HYDROLOGIC_IMAGE_REQUEST: "GET_HYDROLOGIC_IMAGE_REQUEST",
    GET_HYDROLOGIC_IMAGE_SUCCESS: "GET_HYDROLOGIC_IMAGE_SUCCESS",
    GET_HYDROLOGIC_IMAGE_FAILURE: "GET_HYDROLOGIC_IMAGE_FAILURE",
    GET_HYDROLOGIC_IMAGE_RESET: "GET_HYDROLOGIC_IMAGE_RESET",
    CONFIRM_CONTRACT_REQUEST: "CONFIRM_CONTRACT_REQUEST",
    CONFIRM_CONTRACT_SUCCESS: "CONFIRM_CONTRACT_SUCCESS",
    CONFIRM_CONTRACT_FAILURE: "CONFIRM_CONTRACT_FAILURE",
    ADD_CONTRACT_REQUEST: "ADD_CONTRACT_REQUEST",
    ADD_CONTRACT_SUCCESS: "ADD_CONTRACT_SUCCESS",
    ADD_CONTRACT_FAILURE: "ADD_CONTRACT_FAILURE",
    DELETE_CONTRACT_REQUEST: "DELETE_CONTRACT_REQUEST",
    DELETE_CONTRACT_SUCCESS: "DELETE_CONTRACT_SUCCESS",
    DELETE_CONTRACT_FAILURE: "DELETE_CONTRACT_FAILURE"
};
