import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import { login, forgotPassword, resetForgotPassword } from "../../User/actions";

import {
    IconButton,
    FormHelperText,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    FormControl,
    TextField,
    Grid,
    InputAdornment,
    Button
} from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import withStyles from "@material-ui/core/styles/withStyles";
import loginStyles from "../../../assets/css/authStyles.jsx";
import CustomButton from "../../../components/CustomButton";

import logo from "../../../assets/img/hidroven.png";
import google_play from "../../../assets/img/google-play-badge.png";
import app_store from "../../../assets/img/app-store-logo.svg";

class Login extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            email: "",
            password: "",
            submitted: false,
            showPassword: false,
            invalidEmail: false,
            touched: {
                email: false,
                password: false
            },
            forgotPassword: false
        };
    }

    handleChange = (e) => {
        const { name, value } = e.target;
        this.setState({ [name]: value });
        if (name === "email") {
            this.setState({
                invalidEmail: !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
            });
        }
    };

    handleSubmit = (e) => {
        e.preventDefault();
        this.setState({ submitted: true });
        const { email, password, invalidEmail, forgotPassword } = this.state;
        const { forgotPswd, login } = this.props;
        if (forgotPassword) {
            forgotPswd(email);
        } else if (email && !invalidEmail && password) {
            login(email, password);
        }
    };

    handleClickShowPassword = () => {
        this.setState((state) => ({ showPassword: !state.showPassword }));
    };

    handleBlur = (field) => (e) => {
        this.setState({ touched: { ...this.state.touched, [field]: true } });
    };

    changeToForgotPassword = () => {
        this.setState({ forgotPassword: !this.state.forgotPassword });
    };

    handleCloseDialog = () => {
        const { resetForgotPassword } = this.props;
        resetForgotPassword();
    };

    render() {
        const { passwordRecoverySuccess, classes, isLogginIn, isRecoveringPassword } = this.props;
        const { email, password, showPassword, invalidEmail, touched, forgotPassword } = this.state;
        return (
            <div className={classes.container}>
                <div id="login" className={classes.paper}>
                    <img src={logo} className={classes.logo} />
                    <h2 className={classes.title}>Hidroweb</h2>
                    <h3>{forgotPassword ? "Recuperar contraseña" : "Iniciar sesión"}</h3>
                    <form onSubmit={this.handleSubmit}>
                        <Grid container direction="column" justify="center" alignItems="center">
                            <FormControl fullWidth={true}>
                                <TextField
                                    label="Email"
                                    type="text"
                                    className={classes.input}
                                    margin="normal"
                                    fullWidth={true}
                                    name="email"
                                    value={email}
                                    error={invalidEmail && touched.email}
                                    onChange={this.handleChange}
                                    onBlur={this.handleBlur("email")}
                                    InputProps={{
                                        classes: {
                                            root: classes.input
                                        }
                                    }}
                                />
                                {invalidEmail && touched.email && (
                                    <FormHelperText id="component-error-text" error>
                                        Correo electrónico inválido
                                    </FormHelperText>
                                )}
                            </FormControl>
                            {!forgotPassword && (
                                <FormControl fullWidth={true}>
                                    <TextField
                                        label="Contraseña"
                                        type={showPassword ? "text" : "password"}
                                        className=""
                                        margin="normal"
                                        fullWidth={true}
                                        name="password"
                                        onChange={this.handleChange}
                                        value={password}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="Toggle password visibility"
                                                        onClick={this.handleClickShowPassword}
                                                    >
                                                        {showPassword ? (
                                                            <VisibilityOff />
                                                        ) : (
                                                            <Visibility />
                                                        )}
                                                    </IconButton>
                                                </InputAdornment>
                                            )
                                        }}
                                    />
                                </FormControl>
                            )}
                            {forgotPassword ? (
                                <div>
                                    <CustomButton
                                        type="submit"
                                        fullWidth={true}
                                        disabled={invalidEmail || email.length === 0}
                                        loading={isRecoveringPassword}
                                    >
                                        Aceptar
                                    </CustomButton>
                                    <a
                                        href="#"
                                        className={classes.link}
                                        onClick={this.changeToForgotPassword}
                                    >
                                        Iniciar sesión
                                    </a>
                                </div>
                            ) : (
                                <div>
                                    <CustomButton
                                        type="submit"
                                        disabled={
                                            invalidEmail ||
                                            email.length === 0 ||
                                            password.length === 0
                                        }
                                        fullWidth={true}
                                        loading={isLogginIn}
                                    >
                                        Iniciar sesión
                                    </CustomButton>
                                    <a
                                        href="#"
                                        className={classes.link}
                                        onClick={this.changeToForgotPassword}
                                    >
                                        ¿Olvidaste tu contraseña?
                                    </a>
                                </div>
                            )}
                            <Link to="/register" className={classes.link}>
                                Regístrate aquí
                            </Link>
                        </Grid>
                    </form>
                    <a
                        href="https://play.google.com/store/apps/details?id=es.inorca.hidromovil"
                        rel="noopener"
                        target="_blank"
                    >
                        <img
                            src={google_play}
                            className={[classes.download_logo, classes.play_store].join(" ")}
                            alt=""
                        />
                    </a>

                    <a
                        href="https://itunes.apple.com/ie/app/hidrom%C3%B3vil/id1294019966?mt=8"
                        rel="noopener"
                        target="_blank"
                    >
                        <img
                            src={app_store}
                            className={[classes.download_logo, classes.app_store].join(" ")}
                        />
                    </a>
                    <Dialog
                        open={passwordRecoverySuccess}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">{"Registro Exitoso"}</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                Se ha enviado un correo electrónico con instrucciones para recuperar
                                la contraseña.
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={this.handleCloseDialog} color="primary" autoFocus>
                                Ok
                            </Button>
                        </DialogActions>
                    </Dialog>
                </div>
            </div>
        );
    }
}

Login.propTypes = {
    passwordRecoverySuccess: PropTypes.bool,
    classes: PropTypes.object.isRequired,
    isLogginIn: PropTypes.bool.isRequired,
    isRecoveringPassword: PropTypes.bool.isRequired
};

const mapStateToProps = (state) => {
    const { passwordRecoverySuccess, isLogginIn, isRecoveringPassword } = state.userReducers;
    return { passwordRecoverySuccess, isLogginIn, isRecoveringPassword };
};

const mapDispatchToProps = {
    login: login,
    forgotPswd: forgotPassword,
    resetForgotPassword: resetForgotPassword
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(loginStyles)(Login));
