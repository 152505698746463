import { billsConstants } from "../../constants";
import { BILLS_PAGE_SIZE } from "../../config";

const initial_state = {
    bills_list: [],
    final_bills_page: false,
    loading_bills: false,
    page: 0
};

export default (state = initial_state, action) => {
    switch (action.type) {
        case billsConstants.GET_BILLS_REQUEST:
            return { ...state, loading_bills: true };

        case billsConstants.GET_BILLS_SUCCESS:
            const list = action.payload.bills_list;
            return {
                ...state,
                bills_list: list,
                final_bills_page: list.length < BILLS_PAGE_SIZE,
                loading_bills: false,
                page: list.length && action.payload.page
            };

        case billsConstants.GET_BILLS_FAILURE:
            return { ...state, loading_bills: false };

        case billsConstants.GET_BILL_SUCCESS:
            return { ...state };

        case billsConstants.RESET_BILLS_LIST:
            return {
                ...state,
                bills_list: [],
                final_bills_page: false
            };

        default:
            return { ...state };
    }
};
