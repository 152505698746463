export const loadUser = () => {
    try {
        const serializedUser = localStorage.getItem("user");
        const serializedToken = localStorage.getItem("token");
        if (serializedUser === null) {
            return undefined;
        }
        return { user_id: JSON.parse(serializedUser), token: JSON.parse(serializedToken) };
    } catch (error) {
        return undefined;
    }
};

export const saveUser = (user, token) => {
    try {
        const serializedUser = JSON.stringify(user);
        const serializedToken = JSON.stringify(token);
        localStorage.setItem("user", serializedUser);
        localStorage.setItem("token", serializedToken);
    } catch (error) {
        // TODO
    }
};
