import React from "react";
import { connect } from "react-redux";
import { getPaymentsList } from "./actions";
import Pagination from "../../components/Pagination";

import withStyles from "@material-ui/core/styles/withStyles";
import billsStyles from "../../assets/css/billsStyles.jsx";

import { Typography, CircularProgress, List, ListItem, ListItemText } from "@material-ui/core";
import format from "number-format.js";
import { appConstants } from "../../constants/app";

class PaymentsList extends React.Component {
    componentDidMount() {
        const { selected_contract_uuid, payments_list, getPaymentsList, page } = this.props;
        if (!payments_list.length) {
            getPaymentsList(selected_contract_uuid, page);
        }
    }

    componentDidUpdate(prevProps) {
        const { selected_contract_uuid, getPaymentsList, page } = this.props;
        if (prevProps.selected_contract_uuid !== selected_contract_uuid) {
            getPaymentsList(selected_contract_uuid, page);
        }
    }

    handlePagination = async (moveForward) => {
        let { page } = this.props;
        const new_page = moveForward ? page + 1 : page - 1;
        const { selected_contract_uuid, getPaymentsList } = this.props;

        await getPaymentsList(selected_contract_uuid, new_page);
    };

    getPaymentPlace = (payment_place, tipo_cobro) => {
        if (tipo_cobro === "Pago en linea") {
            if (payment_place === "ios") {
                return "Hidromóvil iOS";
            } else if (payment_place === "android") {
                return "Hidromóvil Android";
            } else {
                return "Hidroweb";
            }
        }
        return payment_place;
    };

    render() {
        const {
            classes,
            payments_list,
            selected_payment,
            final_payments_page,
            loading_payments,
            page
        } = this.props;

        if (loading_payments) {
            return <CircularProgress className={classes.empty_details} />;
        }
        return (
            <React.Fragment>
                <div className={classes.bills_list}>
                    {payments_list.length ? (
                        <List>
                            {payments_list.map((payment, index) => (
                                <ListItem
                                    key={payment.idCobro}
                                    button
                                    className={classes.bill_item}
                                    selected={selected_payment === index}
                                >
                                    <ListItemText
                                        className={classes.bill_item_text}
                                        secondary={new Intl.DateTimeFormat("es", {
                                            year: "numeric",
                                            month: "long",
                                            day: "numeric"
                                        }).format(new Date(payment.fechaHoraCreacion))}
                                        primary={`${appConstants.CURRENCY} ${
                                            format("#.##0,##", payment.importeTotal) || 0
                                        }`}
                                        primaryTypographyProps={{ variant: "h5" }}
                                        secondaryTypographyProps={{ variant: "subheading" }}
                                    />
                                    <ListItemText
                                        className={classes.payment_place}
                                        primary={this.getPaymentPlace(
                                            payment.claveExternaCaja,
                                            payment.tipoCobro
                                        )}
                                        primaryTypographyProps={{ variant: "h6" }}
                                    />
                                </ListItem>
                            ))}
                        </List>
                    ) : (
                        <div className={classes.empty_details}>
                            <Typography variant="subheading">
                                No se encuentran pagos asociados al contrato seleccionado.
                            </Typography>
                        </div>
                    )}
                </div>
                <Pagination
                    page={page}
                    final_page={final_payments_page}
                    callToPagination={this.handlePagination}
                />
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    const { payments_list, final_payments_page, loading_payments, page } = state.paymentsReducers;
    const { selected_contract_uuid } = state.contractsReducers;
    return { payments_list, final_payments_page, loading_payments, selected_contract_uuid, page };
};

const mapDispatchToProps = {
    getPaymentsList
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(billsStyles)(PaymentsList));
