import { combineReducers } from "redux";
import userReducers from "../containers/User/reducers";
import appReducers from "../containers/App/reducers";
import contractsReducers from "../containers/Contract/reducers";
import billsReducers from "../containers/Bill/reducers";
import paymentsReducers from "../containers/Payment/reducers";
import intakeReducers from "../containers/Intake/reducers";

export default combineReducers({
    userReducers,
    appReducers,
    contractsReducers,
    billsReducers,
    paymentsReducers,
    intakeReducers
});
