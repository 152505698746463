import theme from "./palette.jsx";
const backgroundImg = require("../img/background.jpg");
//linear-gradient(to top left,rgba(191, 209, 228, 0.8), rgba(57, 156, 219, 0.8),rgba(0, 196, 150, 0.7)),
//filter(url(path/to/img.jpg), blur(5px))
const loginStyles = {
    container: {
        height: "100%",
        minHeight: "400px",
        backgroundImage: `url(${backgroundImg})`,
        backgroundSize: "cover",
        overflow: "visible",
        backgroundPosition: "center",
        overflowY: "auto"
    },
    paper: {
        backgroundColor: "rgba(255,255,255,0.8)",
        width: "40%",
        margin: "5% auto",
        zIndex: 10,
        padding: "2% 5%",
        borderRadius: "10px",
        [theme.breakpoints.down("xs")]: {
            margin: "0 auto",
            paddingTop: "10%",
            paddingBottom: "55%",
            width: "90%"
        },
        [theme.breakpoints.down("sm")]: {
            margin: "0 auto",
            paddingTop: "10%",
            paddingBottom: "55%",
            width: "90%"
        }
    },

    logo: {
        width: "10em"
    },
    title: {
        marginTop: "0",
        fontWeight: "400"
    },
    link: {
        color: theme.palette.primary.main
    },
    user_form_container: {
        width: "20em",
        padding: theme.spacing.unit * 4,
        margin: "auto",
        textAlign: "left"
    },
    margin: {
        marginTop: theme.spacing.unit,
        marginBottom: theme.spacing.unit
    },
    marginExt: {
        marginTop: theme.spacing.unit * 3,
        marginBottom: theme.spacing.unit * 3,
        marginLeft: theme.spacing.unit * 4
    },
    download_logo: {
        position: "relative"
    },
    app_store: {
        width: "10em"
    },
    play_store: {
        width: "12.5em",
        top: "0.7em"
    },
    circular_progress: {
        margin: "auto"
    }
};

export default loginStyles;
