import { history } from "../../_helpers/history";
import { userConstants, appConstants } from "../../constants";
import axios, { setUpAuthorization } from "../../utilities/axiosSetUp";
import { setLoginSuccess } from "../../utilities/analytics";
import _ from "lodash";

export const login = (email, password) => async (dispatch) => {
    try {
        dispatch({ type: userConstants.LOGIN_REQUEST, email });
        const response = await axios.put("/usuarios/login/", { email, password });
        dispatch({ type: userConstants.LOGIN_SUCCESS, payload: response.data });
        setLoginSuccess(email);
        history.push("/");
    } catch (error) {
        dispatch({ type: userConstants.LOGIN_FAILURE });
        dispatch({
            type: appConstants.SHOW_MESSAGE,
            payload:
                error.response.status !== 500
                    ? error.response.data
                    : {
                          description:
                              "En este momento no se puede acceder. Por favor, intentar más tarde."
                      }
        });
    }
};

export const logout = () => (dispatch) => {
    localStorage.removeItem("user");
    localStorage.removeItem("token");
    dispatch({ type: userConstants.LOGOUT });
    history.push("/");
};

export const signup =
    (name, lastname, documentNumber, phone, email, password) => async (dispatch) => {
        const request_object = {
            nombre: name,
            apellido: lastname,
            documento: documentNumber,
            telefono: phone,
            email,
            password
        };
        try {
            dispatch({ type: userConstants.SIGNUP_REQUEST });
            const response = await axios.post("/usuarios/", request_object);
            dispatch({ type: userConstants.SIGNUP_SUCCESS, payload: response.data });
        } catch (error) {
            dispatch({ type: userConstants.SIGNUP_FAILURE });
            dispatch({
                type: appConstants.SHOW_MESSAGE,
                payload: error.response
                    ? error.response.data
                    : {
                          description:
                              "En este momento no se puede acceder. Por favor, intentar más tarde."
                      }
            });
        }
    };

export const resetSignedUp = () => {
    return { type: userConstants.SIGNEDUP_RESET };
};

export const forgotPassword = (email) => async (dispatch) => {
    try {
        dispatch({ type: userConstants.FORGOT_PASSWORD_REQUEST });
        await axios.put("/usuarios/olvidarpassword", { email });
        dispatch({ type: userConstants.FORGOT_PASSWORD_SUCCESS });
    } catch (error) {
        if (error.response.status === 404) {
            dispatch({ type: userConstants.FORGOT_PASSWORD_FAILURE });
            dispatch({
                type: appConstants.SHOW_MESSAGE,
                payload: { description: "El correo electrónico enviado no existe" }
            });
        } else {
            dispatch({ type: userConstants.FORGOT_PASSWORD_FAILURE });
            dispatch({ type: appConstants.SHOW_MESSAGE, payload: error.response.data });
        }
    }
};

export const resetForgotPassword = () => {
    return { type: userConstants.FORGOT_PASSWORD_RESET };
};

export const getUser = (user_uuid) => async (dispatch) => {
    try {
        dispatch({ type: userConstants.GET_USER_REQUEST });
        setUpAuthorization();
        const response = await axios.get(`/usuarios/${user_uuid}`);
        dispatch({ type: userConstants.GET_USER_SUCCESS, payload: response.data });
    } catch (error) {
        dispatch({ type: userConstants.GET_USER_FAILURE });
        dispatch({
            type: appConstants.SHOW_MESSAGE,
            payload: {
                description:
                    "La información del perfil de usuario no se pudo acceder sastifactoriamente. Por favor, intentar más tarde.",
                error_code: error.response.status
            }
        });
    }
};

export const editUser =
    (user_uuid, user_name, user_surname, user_document, user_phone, user_version) =>
    async (dispatch) => {
        try {
            setUpAuthorization();
            dispatch({ type: userConstants.EDIT_USER_REQUEST });
            const user_data = {
                nombre: user_name,
                apellido: user_surname,
                documento: user_document,
                telefono: user_phone,
                version: user_version
            };
            const response = await axios.put(`/usuarios/${user_uuid}`, user_data);
            dispatch({ type: userConstants.EDIT_USER_SUCCESS, payload: response.data });
            dispatch({
                type: appConstants.SHOW_MESSAGE,
                payload: {
                    description:
                        "La información del perfil de usuario se ha actualizado sastifactoriamente."
                }
            });
        } catch (error) {
            dispatch({ type: userConstants.EDIT_USER_FAILURE });
            dispatch({
                type: appConstants.SHOW_MESSAGE,
                payload: {
                    description:
                        "La información del perfil de usuario no se pudo actualizar sastifactoriamente. Por favor, intentar más tarde."
                }
            });
        }
    };

export const changePassword = (user_uuid, new_password, user_version) => async (dispatch) => {
    try {
        setUpAuthorization();
        dispatch({ type: userConstants.CHANGE_PASSWORD_REQUEST });
        const user_data = {
            password: new_password,
            version: user_version
        };
        const response = await axios.put(`/usuarios/${user_uuid}/cambiarpassword`, user_data);
        dispatch({ type: userConstants.CHANGE_PASSWORD_SUCCESS, payload: response.data });
        dispatch({
            type: appConstants.SHOW_MESSAGE,
            payload: { description: "La contraseña se ha actualizado sastifactoriamente." }
        });
    } catch (error) {
        dispatch({ type: userConstants.CHANGE_PASSWORD_FAILURE });
        dispatch({
            type: appConstants.SHOW_MESSAGE,
            payload: {
                description:
                    "La nueva contraseña no se pudo actualizar sastifactoriamente. Por favor, intentar más tarde."
            }
        });
    }
};

export const setUserFromLocalStorage = (user_id, token) => async (dispatch) => {
    dispatch({ type: userConstants.LOGIN_REQUEST });
    dispatch({ type: userConstants.LOGIN_SUCCESS, payload: { usuario: user_id, token } });
};
