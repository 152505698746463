import { appConstants } from "../../constants/app";

export const showMessage = (message, error_code) => {
    return { type: appConstants.SHOW_MESSAGE, payload: { description: message, error_code } };
};

export const clearMessage = () => {
    return { type: "CLEAR_MESSAGE" };
};

export const loadingApp = () => {
    return { type: appConstants.IS_LOADING };
};
