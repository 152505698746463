import { contractsConstants } from "../../constants";

const initial_state = {
    gettingList: false,
    contracts_list: [],
    selected_contract: null,
    selected_contract_id: null,
    selected_contract_debt: null,
    selected_contract_detail: null,
    selected_contract_available: null,
    selected_contract_uuid: null,
    selected_contract_hydrologic_uuid: null,
    selected_contract_hydrologic_name: null,
    online_payment_available: null,
    get_contract_failure: false,
    hydrological_list: null,
    hydrological_image: [],
    contract_exists: null,
    contract_name: null,
    contract_address: null,
    contract_id: null,
    hydrologic_id: null,
    hydrologic_name: null,
    is_verifying: false,
    loading_contract: false
};

export default (state = initial_state, action) => {
    const contracts_list = Array.from(state.contracts_list);

    switch (action.type) {
        case contractsConstants.GET_CONTRACTS_LIST_REQUEST:
            return { ...state, gettingList: true };

        case contractsConstants.GET_CONTRACTS_LIST_SUCCESS:
            return { ...state, contracts_list: action.payload, gettingList: false };

        case contractsConstants.GET_CONTRACTS_LIST_FAILURE:
            return { ...state, gettingList: false };

        case contractsConstants.GET_CONTRACT_REQUEST:
            return { ...state, loading_contract: true };

        case contractsConstants.GET_CONTRACT_SUCCESS:
            return {
                ...state,
                selected_contract: true,
                selected_contract_uuid: action.payload.contract_uuid,
                selected_contract_id: action.payload.contract_id,
                selected_contract_debt: action.payload.debt,
                selected_contract_detail: action.payload.detail,
                selected_contract_hydrologic_uuid: action.payload.hydrologic_uuid,
                selected_contract_hydrologic_name: action.payload.hydrologic_name,
                loading_contract: false
            };

        case contractsConstants.GET_CONTRACT_FAILURE:
            return {
                ...state,
                selected_contract: false,
                selected_contract_id: null,
                loading_contract: false
            };

        case contractsConstants.GET_CONTRACT_RESET:
            return {
                ...state,
                selected_contract: false,
                selected_contract_uuid: null,
                selected_contract_id: null,
                selected_contract_debt: null,
                selected_contract_detail: null,
                selected_contract_hydrologic_uuid: null,
                selected_contract_hydrologic_name: null
            };

        case contractsConstants.GET_HYDROLOGIC_REQUEST:
            return { ...state, selected_contract_available: null, online_payment_available: null };

        case contractsConstants.GET_HYDROLOGIC_SUCCESS:
            return {
                ...state,
                selected_contract_available: action.payload.enServicio,
                online_payment_available: action.payload.pagoEnLineaActivo
            };

        case contractsConstants.GET_HYDROLOGIC_FAILURE:
            return { ...state, selected_contract_available: false, online_payment_available: null };

        case contractsConstants.GET_HYDROLOGIC_LIST_SUCCESS:
            return { ...state, hydrological_list: action.payload };

        case contractsConstants.GET_HYDROLOGIC_LIST_FAILURE:
            return { ...state };

        case contractsConstants.GET_HYDROLOGIC_IMAGE_SUCCESS:
            return { ...state, hydrological_image: [...state.hydrological_image, action.payload] };

        case contractsConstants.GET_HYDROLOGIC_IMAGE_RESET:
            return { ...state, hydrological_image: [] };

        case contractsConstants.GET_HYDROLOGIC_IMAGE_FAILURE:
            return { ...state };

        case contractsConstants.CONFIRM_CONTRACT_REQUEST:
            return {
                ...state,
                contract_exists: null,
                is_verifying: true
            };

        case contractsConstants.CONFIRM_CONTRACT_SUCCESS:
            return {
                ...state,
                contract_exists: true,
                contract_name: action.payload.nombre,
                contract_address: action.payload.direccionSuministro,
                contract_id: action.contract_id,
                hydrologic_id: action.hydrologic_id,
                hydrologic_name: action.hydrologic_name,
                is_verifying: false
            };

        case contractsConstants.CONFIRM_CONTRACT_FAILURE:
            return {
                ...state,
                contract_exists: false,
                is_verifying: false
            };

        case contractsConstants.ADD_CONTRACT_SUCCESS:
            const new_contract = action.payload;
            contracts_list.push(new_contract);

            return { ...state, contracts_list };

        case contractsConstants.ADD_CONTRACT_FAILURE:
            return { ...state };

        case contractsConstants.DELETE_CONTRACT_SUCCESS:
            const contract_id = action.payload.contract_id;
            const index = contracts_list.findIndex(
                (element) => element.claveExterna === contract_id
            );
            contracts_list.splice(index, 1);

            return { ...state, contracts_list };

        case contractsConstants.DELETE_CONTRACT_FAILURE:
            return { ...state };

        default:
            return { ...state };
    }
};
