// API Config
export const API_BASE_URL = process.env.API_BASE_URL;
export const API_HEADER_ACCESS_KEY = process.env.API_HEADER_ACCESS_KEY;
export const API_HEADER_ACCESS_VALUE = process.env.API_HEADER_ACCESS_VALUE;
export const REQUEST_TIMEOUT = 60000;
export const DIRECTORY_BASENAME = process.env.DIRECTORY_BASENAME;

// Hidrológicas
// TODO: Mejorar este import en un solo objeto
export const HIDROCENTRO = "Hidrocentro";
export const HIDROCAPITAL = "Hidrocapital";
export const HIDROCARIBE = "Hidrocaribe";
export const HIDROLAGO = "Hidrolago";
export const HIDROFALCON = "Hidrofalcón";
export const HIDROLLANOS = "Hidrollanos";
export const HIDROSUROESTE = "Hidrosuroeste";
export const HIDROANDES = "Hidroandes";
export const HIDROPAEZ = "Hidropáez";

// Número de elementos de página de Pagos
export const BILLS_PAGE_SIZE = 10;

// Objecto de configuraciones para Google Analytics
export const DEFAULT_GA_CONFIG = {
    trackingId: process.env.GA_TRACK_ID
};

export const CHECKMARK_ANIMATION = process.env.CHECKMARK_ANIMATION;

export const MONTHS = [
    "Enero",
    "Febrero",
    "Marzo",
    "Abril",
    "Mayo",
    "Junio",
    "Julio",
    "Agosto",
    "Septiembre",
    "Octubre",
    "Noviembre",
    "Diciembre"
];
