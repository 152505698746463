import { paymentsConstants } from "../../constants";
import { BILLS_PAGE_SIZE } from "../../config";

const initial_state = {
    payments_list: [],
    final_payments_page: false,
    loading_payment: false,
    successful_payment: false,
    voucher: null,
    processing_payment: false,
    loading_payments: false,
    page: 0
};

export default (state = initial_state, action) => {
    switch (action.type) {
        case paymentsConstants.GET_PAYMENTS_REQUEST:
            return { ...state, loading_payments: true };

        case paymentsConstants.GET_PAYMENTS_SUCCESS:
            const list = action.payload.payments_list;
            return {
                ...state,
                payments_list: list,
                final_payments_page: list.length < BILLS_PAGE_SIZE,
                loading_payments: false,
                page: list.length && action.payload.page
            };

        case paymentsConstants.GET_PAYMENTS_FAILURE:
            return { ...state, loading_payments: false };

        case paymentsConstants.POST_PAYMENT_REQUEST:
            return { ...state, processing_payment: true };

        case paymentsConstants.POST_PAYMENT_SUCCESS:
            return {
                ...state,
                successful_payment: true,
                voucher: action.payload.ticket,
                processing_payment: false
            };

        case paymentsConstants.POST_PAYMENT_FAILURE:
            return {
                ...state,
                successful_payment: false,
                processing_payment: false
            };

        case paymentsConstants.POST_PAYMENT_SERVER_FAILURE:
            return {
                ...state,
                successful_payment: false,
                processing_payment: false
            };
        case paymentsConstants.CLEAR_PAYMENT_DATA:
            return {
                ...state,
                successful_payment: false,
                processing_payment: false,
                voucher: null
            };
        case paymentsConstants.RESET_PAYMENTS_LIST:
            return {
                ...state,
                payments_list: [],
                final_payments_page: false
            };
        default:
            return { ...state };
    }
};
