import React, { Fragment } from "react";
import { DescriptionOutlined } from "@material-ui/icons";
import { Typography, CircularProgress } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import homeStyles from "../../../assets/css/homeStyles.jsx";

const NoContractSelected = ({ classes, loading_contract }) => {
    return (
        <div className={classes.empty_details}>
            {loading_contract ? (
                <CircularProgress className={classes.details_loader} />
            ) : (
                <Fragment>
                    <DescriptionOutlined style={{ fill: "rgb(117, 117, 117)", fontSize: "8em" }} />
                    <Typography variant="subheading">
                        Selecciona un contrato para ver Detalles, Facturas, Pagos y Consumos
                    </Typography>
                </Fragment>
            )}
        </div>
    );
};

export default withStyles(homeStyles)(NoContractSelected);
