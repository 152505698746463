import { appConstants } from "../../constants/app";

const initial_state = {
    message: null,
    is_loading: false,
    error_code: null
};

export default (state = initial_state, action) => {
    const matches = /(.*)_(REQUEST|SUCCESS|FAILURE)/.exec(action.type);

    if (matches) {
        return { ...state, is_loading: !state.is_loading, error_code: null };
    }

    switch (action.type) {
        case appConstants.SHOW_MESSAGE:
            return {
                ...state,
                message:
                    action.payload.description ||
                    (action.payload.errors && action.payload.errors.pop().message) ||
                    null,
                error_code: action.payload.error_code
            };

        case "CLEAR_MESSAGE":
            return {
                ...state,
                message: null,
                error_code: null
            };

        default:
            return state;
    }
};
