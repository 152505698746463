import React from "react";
import { Tabs, Tab } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import homeStyles from "../../../assets/css/homeStyles.jsx";
import ContractDetail from "../../Contract/ContractDetail";
import BillsList from "../../Bill";
import PaymentsList from "../../Payment";
import Intake from "../../Intake";

const DetailsTab = ({ classes, tabIndex, handleTabsChange }) => {
    return (
        <div className={classes.details_container}>
            <Tabs
                classes={{ indicator: classes.tabIndicator }}
                centered
                onChange={handleTabsChange}
                value={tabIndex}
            >
                <Tab
                    label="Detalle"
                    classes={{ root: classes.tab, selected: classes.tabSelected }}
                />
                <Tab
                    label="Facturas"
                    classes={{ root: classes.tab, selected: classes.tabSelected }}
                />
                <Tab label="Pagos" classes={{ root: classes.tab, selected: classes.tabSelected }} />
                <Tab
                    label="Consumos"
                    classes={{ root: classes.tab, selected: classes.tabSelected }}
                />
            </Tabs>
            {tabIndex === 0 && <ContractDetail />}
            {tabIndex === 1 && <BillsList />}
            {tabIndex === 2 && <PaymentsList />}
            {tabIndex === 3 && <Intake />}
        </div>
    );
};

export default withStyles(homeStyles)(DetailsTab);
