export const userConstants = {
    LOGIN_REQUEST: "USER_LOGIN_REQUEST",
    LOGIN_SUCCESS: "USER_LOGIN_SUCCESS",
    LOGIN_FAILURE: "USER_LOGIN_FAILURE",

    LOGOUT: "USER_LOGOUT",

    SIGNUP_REQUEST: "USER_SIGNUP_REQUEST",
    SIGNUP_SUCCESS: "USER_SIGNUP_SUCCESS",
    SIGNUP_FAILURE: "USER_SIGNUP_FAILURE",
    SIGNEDUP_RESET: "USER_SIGNEDUP_RESET",

    FORGOT_PASSWORD_REQUEST: "USER_FORGOT_PASSWORD_REQUEST",
    FORGOT_PASSWORD_SUCCESS: "USER_FORGOT_PASSWORD_SUCCESS",
    FORGOT_PASSWORD_RESET: "USER_FORGOT_PASSWORD_RESET",
    FORGOT_PASSWORD_FAILURE: "USER_FORGOT_PASSWORD_FAILURE",

    GET_USER_REQUEST: "GET_USER_REQUEST",
    GET_USER_SUCCESS: "GET_USER_SUCCESS",
    GET_USER_FAILURE: "GET_USER_FAILURE",

    EDIT_USER_REQUEST: "EDIT_USER_REQUEST",
    EDIT_USER_SUCCESS: "EDIT_USER_SUCCESS",
    EDIT_USER_FAILURE: "EDIT_USER_FAILURE",

    CHANGE_PASSWORD_REQUEST: "CHANGE_PASSWORD_REQUEST",
    CHANGE_PASSWORD_SUCCESS: "CHANGE_PASSWORD_SUCCESS",
    CHANGE_PASSWORD_FAILURE: "CHANGE_PASSWORD_FAILURE"
};
