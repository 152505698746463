import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { Link } from "react-router-dom";
import { signup, resetSignedUp } from "../../User/actions";

import CustomButton from "../../../components/CustomButton";
import {
    FormControl,
    TextField,
    Button,
    Grid,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    FormHelperText,
    Paper
} from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import loginStyles from "../../../assets/css/authStyles.jsx";

import logo from "../../../assets/img/hidroven.png";

class SignUp extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            name: "",
            lastname: "",
            document_number: "",
            phone: "",
            email: "",
            password: "",
            password_confirmation: "",
            submitted: false,
            invalidEmail: false,
            invalidPasswordConfirmation: false,
            invalidPassword: false,
            isFormInvalid: true
        };
    }

    handleChange = (e) => {
        const { name, value } = e.target;
        this.setState({ [name]: value });
        if (name === "email") {
            this.setState({
                invalidEmail: !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
            });
        }
        if (name === "password") {
            this.setState({ invalidPassword: value.length < 8 });
        }
        if (name === "password" && this.state.password_confirmation.length) {
            this.setState({
                invalidPasswordConfirmation: this.state.password_confirmation !== value
            });
        }
        if (name === "password_confirmation") {
            this.setState({ invalidPasswordConfirmation: this.state.password !== value });
        }
        this.setState((state) => ({
            isFormInvalid: this.isFormValid(state)
        }));
    };

    isFormValid = (state) => {
        const {
            name,
            lastname,
            document_number,
            phone,
            email,
            password,
            password_confirmation,
            invalidEmail,
            invalidPassword,
            invalidPasswordConfirmation
        } = state;
        return (
            name === "" ||
            lastname === "" ||
            document_number === "" ||
            phone === "" ||
            email === "" ||
            password === "" ||
            password_confirmation === "" ||
            invalidEmail ||
            invalidPassword ||
            invalidPasswordConfirmation
        );
    };

    handleSubmit = (e) => {
        e.preventDefault();
        this.setState({ submitted: true });
        const { signup } = this.props;
        const { name, lastname, document_number, phone, email, password } = this.state;
        signup(name, lastname, document_number, phone, email, password);
    };

    handleCloseDialog = () => {
        const { resetSignedUp } = this.props;
        resetSignedUp();
    };

    render() {
        const {
            name,
            lastname,
            document_number,
            phone,
            email,
            password,
            password_confirmation,
            invalidEmail,
            invalidPassword,
            invalidPasswordConfirmation,
            isFormInvalid
        } = this.state;
        const { signedup, classes, isSigningup } = this.props;
        return (
            <div className={classes.container}>
                <div className={classes.paper}>
                    <img src={logo} className={classes.logo} />
                    <Dialog
                        open={signedup}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">{"Registro Exitoso"}</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                El usuario ha sido agregado exitosamente, por favor confirme el
                                registro en su correo electrónico.
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={this.handleCloseDialog} color="primary" autoFocus>
                                Ok
                            </Button>
                        </DialogActions>
                    </Dialog>
                    <h2 className={classes.title}>Hidroweb</h2>
                    <h3>Registrar</h3>
                    <form onSubmit={this.handleSubmit}>
                        <Grid container direction="column" justify="center" alignItems="center">
                            <FormControl fullWidth={true}>
                                <TextField
                                    label="Nombre"
                                    type="text"
                                    margin="normal"
                                    name="name"
                                    onChange={this.handleChange}
                                    value={name}
                                    required
                                />
                            </FormControl>
                            <FormControl fullWidth={true}>
                                <TextField
                                    label="Apellido"
                                    type="text"
                                    margin="normal"
                                    name="lastname"
                                    onChange={this.handleChange}
                                    value={lastname}
                                    required
                                />
                            </FormControl>
                            <FormControl fullWidth={true}>
                                <TextField
                                    label="Cédula"
                                    type="text"
                                    margin="normal"
                                    name="document_number"
                                    onChange={this.handleChange}
                                    value={document_number}
                                    required
                                />
                            </FormControl>
                            <FormControl fullWidth={true}>
                                <TextField
                                    label="Teléfono"
                                    type="number"
                                    margin="normal"
                                    name="phone"
                                    onChange={this.handleChange}
                                    value={phone}
                                    required
                                />
                            </FormControl>
                            <FormControl fullWidth={true}>
                                <TextField
                                    label="Correo electrónico"
                                    type="text"
                                    margin="normal"
                                    name="email"
                                    onChange={this.handleChange}
                                    value={email}
                                    required
                                    error={invalidEmail}
                                />
                                {invalidEmail && (
                                    <FormHelperText id="component-error-text4" error>
                                        Correo electrónico inválido
                                    </FormHelperText>
                                )}
                            </FormControl>
                            <FormControl fullWidth={true}>
                                <TextField
                                    label="Contraseña"
                                    type="password"
                                    margin="normal"
                                    name="password"
                                    onChange={this.handleChange}
                                    value={password}
                                    required
                                    error={invalidPassword}
                                />
                                {invalidPassword && (
                                    <FormHelperText id="component-error-text2" error>
                                        La contraseña debe tener mínimo 8 caracteres
                                    </FormHelperText>
                                )}
                            </FormControl>
                            <FormControl fullWidth={true}>
                                <TextField
                                    label="Confirmar contraseña"
                                    type="password"
                                    margin="normal"
                                    name="password_confirmation"
                                    onChange={this.handleChange}
                                    value={password_confirmation}
                                    required
                                    error={invalidPasswordConfirmation}
                                />
                                {invalidPasswordConfirmation && (
                                    <FormHelperText id="component-error-text3" error>
                                        La contraseña debe coincidir
                                    </FormHelperText>
                                )}
                            </FormControl>
                            <CustomButton
                                type="submit"
                                disabled={isFormInvalid}
                                fullWidth={true}
                                loading={isSigningup}
                            >
                                Registrar
                            </CustomButton>
                            <Link to="/login" className={classes.link}>
                                Iniciar sesión
                            </Link>
                        </Grid>
                    </form>
                </div>
            </div>
        );
    }
}

SignUp.propTypes = {
    signedup: PropTypes.bool,
    classes: PropTypes.object.isRequired,
    isSigningup: PropTypes.bool.isRequired
};

const mapStateToProps = (state) => {
    const { signedup, isSigningup } = state.userReducers;
    return { signedup, isSigningup };
};

const mapDispatchToProps = {
    signup,
    resetSignedUp
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(loginStyles)(SignUp));
