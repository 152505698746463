import React, { Fragment } from "react";
import CustomButton from "../../../components/CustomButton";
import { IconButton, withStyles } from "@material-ui/core";
import { NoteAdd, Delete } from "@material-ui/icons";
import homeStyles from "../../../assets/css/homeStyles.jsx";

const ActionBar = ({ classes, selected_contract, handleCloseDialog, confirmDeleteContract }) => {
    return (
        <div style={{ width: "100%", display: "block", justifyContent: "center" }}>
            <CustomButton type="button" fullWidth={false} onClick={handleCloseDialog}>
                <NoteAdd className={classes.add_contract_icon} />
                Agregar contrato
            </CustomButton>
            <IconButton
                aria-label="Eliminar"
                disabled={!selected_contract}
                onClick={confirmDeleteContract}
            >
                <Delete />
            </IconButton>
        </div>
    );
};

export default withStyles(homeStyles)(ActionBar);
