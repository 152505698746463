import React from "react";
import { Typography, IconButton, Grid } from "@material-ui/core";

import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";

const Pagination = ({ callToPagination, page, final_page }) => {
    return (
        <Grid container alignItems="center" justify="center">
            <Grid item>
                <IconButton
                    color="primary"
                    onClick={(e) => {
                        callToPagination(false);
                    }}
                    disabled={page === 0}
                >
                    <KeyboardArrowLeft />
                </IconButton>
            </Grid>
            <Grid item>
                <Typography variant="subheading">{page + 1}</Typography>
            </Grid>
            <Grid item>
                <IconButton
                    color="primary"
                    onClick={(e) => {
                        callToPagination(true);
                    }}
                    disabled={final_page}
                >
                    <KeyboardArrowRight />
                </IconButton>
            </Grid>
        </Grid>
    );
};

export default Pagination;
