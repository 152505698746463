import { paymentsConstants, appConstants } from "../../constants";
import axios, { setUpAuthorization } from "../../utilities/axiosSetUp";
import { BILLS_PAGE_SIZE } from "../../config";
import { payDebtSuccess, payDebtFail } from "../../utilities/analytics";
import { REQUEST_TIMEOUT } from "../../config";

export const getPaymentsList = (contrato_uuid, page) => async (dispatch) => {
    try {
        dispatch({ type: paymentsConstants.GET_PAYMENTS_REQUEST });
        setUpAuthorization();
        const response = await axios.get(
            `contratos/${contrato_uuid}/cobros?first=${
                page * BILLS_PAGE_SIZE
            }&pagesize=${BILLS_PAGE_SIZE}&sorttype=DESC`
        );
        dispatch({
            type: paymentsConstants.GET_PAYMENTS_SUCCESS,
            payload: { payments_list: response.data, page: page }
        });
    } catch (error) {
        dispatch({ type: paymentsConstants.GET_PAYMENTS_FAILURE });
        dispatch({ type: appConstants.SHOW_MESSAGE, payload: error.response.data });
    }
};

export const postPaymentDebt =
    (
        contract_uuid,
        contract_id,
        debt,
        payment_reference,
        name,
        document_number,
        card_number,
        cvc,
        expiration_date,
        hydrologic_name
    ) =>
    async (dispatch) => {
        try {
            dispatch({ type: paymentsConstants.POST_PAYMENT_REQUEST });
            setUpAuthorization();
            const response = await axios.post(
                `contratos/${contract_uuid}/pago`,
                {
                    contrato: contract_uuid,
                    importe: debt,
                    referenciaPago: payment_reference,
                    titularTarjeta: name,
                    identificacionTitular: document_number,
                    numeroTarjeta: card_number,
                    cvv: cvc,
                    vencimiento: expiration_date
                },
                {
                    timeout: REQUEST_TIMEOUT * 2
                }
            );
            if (response.data.error) {
                dispatch({ type: paymentsConstants.POST_PAYMENT_SERVER_FAILURE });
                dispatch({
                    type: appConstants.SHOW_MESSAGE,
                    payload: { description: response.data.error }
                });
                payDebtFail(hydrologic_name, contract_id);
            } else {
                dispatch({ type: paymentsConstants.POST_PAYMENT_SUCCESS, payload: response.data });
                payDebtSuccess(hydrologic_name, contract_id);
            }
        } catch (error) {
            dispatch({ type: paymentsConstants.POST_PAYMENT_FAILURE });
            dispatch({
                type: appConstants.SHOW_MESSAGE,
                payload: {
                    description:
                        "Ocurrió un error al procesar el pago. Por favor, intenta más tarde."
                }
            });
            payDebtFail(hydrologic_name, contract_id);
        }
    };

export const clearPaymentData = () => {
    return { type: paymentsConstants.CLEAR_PAYMENT_DATA };
};
