import React, { Fragment } from "react";
import PropTypes from "prop-types";

import withStyles from "@material-ui/core/styles/withStyles";
import checkmarkAnimation from "../../../assets/css/checkmark-animation.jsx";

import { AllHtmlEntities } from "html-entities";
import Lottie from "react-lottie";

import { Typography, Paper } from "@material-ui/core";
import { Link } from "react-router-dom";
import { CHECKMARK_ANIMATION } from "../../../config";

const PaymentProcessing = ({ voucher, classes, clearPaymentData }) => {
    const entities = new AllHtmlEntities();
    const element = entities.decode(voucher);
    const default_options = {
        renderer: "svg",
        autoplay: true,
        path: CHECKMARK_ANIMATION,
        loop: false
    };

    return (
        <div className={classes.wrapper}>
            <Lottie options={default_options} height={200} width={200} />
            <Typography variant="headline">Pago exitoso</Typography>
            {voucher ? (
                <Fragment>
                    <Typography variant="subheading" paragraph>
                        Se ha enviado el recibo de pago al correo electrónico registrado.
                    </Typography>
                    <div
                        className={classes.voucher}
                        dangerouslySetInnerHTML={{ __html: element }}
                    />
                </Fragment>
            ) : (
                <Typography variant="subheading" paragraph>
                    Recibo de pago no disponible
                </Typography>
            )}
            <Link to="/" className={classes.link} onClick={clearPaymentData}>
                Volver a Contratos
            </Link>
        </div>
    );
};

PaymentProcessing.propTypes = {
    successful_payment: PropTypes.bool,
    voucher: PropTypes.string,
    classes: PropTypes.object.isRequired
};

export default withStyles(checkmarkAnimation)(PaymentProcessing);
