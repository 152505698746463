import theme from "./palette.jsx";

const billsStyles = {
    bills_list: {
        overflowY: "scroll",
        height: "29em"
    },
    bill_item_text: {
        flexGrow: "0"
    },
    download_icon: {
        color: "white"
    },
    empty_details: {
        marginTop: "20%"
    },
    chart_styles: {
        width: "33em",
        height: "23em",
        margin: "0 auto",
        marginTop: "3em"
    },
    payment_place: {
        textAlign: "right"
    },
    form_wrapper: {
        maxWidth: "25em",
        display: "flex",
        flexWrap: "wrap",
        margin: "auto"
    },
    small_input: {
        width: "6em"
    },
    cvc_img: {
        width: "13%",
        height: "8%",
        marginTop: "29px",
        marginLeft: "25px"
    },
    instapago_banesco: {
        width: "80%",
        height: "50%"
    },
    steps_container: {
        width: "50%",
        marginLeft: "auto",
        marginRight: "auto",
        left: "0",
        right: "0",
        [theme.breakpoints.down("md")]: {
            width: "70%"
        }
    },
    text_right: {
        textAlign: "right"
    },
    text_left: {
        textAlign: "left"
    },
    details_wrapper: {
        margin: "auto"
    },
    details_title: {
        color: "rgb(147, 154, 165)"
    },
    details_section: {
        marginTop: "1em",
        marginBottom: "1em"
    },
    action_buttons_wrapper: {
        margin: "auto",
        maxWidth: "25em",
        position: "relative"
    },
    transaction_message: {
        margin: "1em auto"
    },
    button_progress: {
        position: "absolute",
        top: "50%",
        left: "50%",
        marginTop: -12,
        marginLeft: -12
    },
    marginExt: {
        marginTop: theme.spacing.unit * 3,
        marginBottom: theme.spacing.unit * 3,
        marginLeft: theme.spacing.unit * 4
    }
};

export default billsStyles;
