import { userConstants } from "../../constants/user";

const initial_state = {
    isLogginIn: false,
    loggedIn: false,
    loggInError: false,
    isSigningup: false,
    signedup: false,
    registerError: true,
    forgotPassword: false,
    isRecoveringPassword: false,
    passwordRecoverySuccess: false,
    user_id: null,
    token: null,
    user_name: null,
    user_surname: null,
    user_document: null,
    user_phone: null,
    user_email: null,
    user_version: null,
    loading_user: false
};

export default (state = initial_state, action) => {
    switch (action.type) {
        case userConstants.LOGIN_REQUEST:
            return { ...state, loggInError: false, isLogginIn: true };
        case userConstants.LOGIN_SUCCESS:
            return {
                ...state,
                loggedIn: true,
                loggInError: false,
                user_id: action.payload.usuario,
                token: action.payload.token,
                isLogginIn: false
            };
        case userConstants.LOGIN_FAILURE:
            return { ...state, loggedIn: false, loggInError: true, isLogginIn: false };
        case userConstants.LOGOUT:
            return { ...state, loggedIn: false, user_id: null, token: null };
        case userConstants.SIGNUP_REQUEST:
            return { ...state, isSigningup: true, registerError: false };
        case userConstants.SIGNUP_SUCCESS:
            return {
                ...state,
                isSigningup: false,
                signedup: true,
                registerError: false,
                user_id: action.user
            };
        case userConstants.SIGNUP_FAILURE:
            return { ...state, isSigningup: false, signedup: false, registerError: true };
        case userConstants.SIGNEDUP_RESET:
            return { ...state, signedup: false, registerError: false };
        case userConstants.FORGOT_PASSWORD_REQUEST:
            return { ...state, isRecoveringPassword: true };
        case userConstants.FORGOT_PASSWORD_SUCCESS:
            return { ...state, passwordRecoverySuccess: true, isRecoveringPassword: false };
        case userConstants.FORGOT_PASSWORD_RESET:
            return { ...state, passwordRecoverySuccess: false, isRecoveringPassword: false };
        case userConstants.FORGOT_PASSWORD_FAILURE:
            return { ...state, passwordRecoverySuccess: false, isRecoveringPassword: false };
        case userConstants.GET_USER_REQUEST:
            return { ...state, loading_user: true };
        case userConstants.GET_USER_SUCCESS:
            return {
                ...state,
                user_name: action.payload.nombre,
                user_surname: action.payload.apellido,
                user_document: action.payload.documento,
                user_phone: action.payload.telefono,
                user_email: action.payload.email,
                user_version: action.payload.version,
                loading_user: false
            };
        case userConstants.GET_USER_FAILURE:
            return { ...state, loading_user: false };
        case userConstants.EDIT_USER_REQUEST:
            return { ...state, loading_user: true };
        case userConstants.EDIT_USER_SUCCESS:
            return {
                ...state,
                loading_user: false,
                user_name: action.payload.nombre,
                user_surname: action.payload.apellido,
                user_document: action.payload.documento,
                user_phone: action.payload.telefono,
                user_version: action.payload.version
            };
        case userConstants.EDIT_USER_FAILURE:
            return { ...state, loading_user: false };
        case userConstants.CHANGE_PASSWORD_REQUEST:
            return { ...state, loading_user: true };
        case userConstants.CHANGE_PASSWORD_SUCCESS:
            return {
                ...state,
                loading_user: false,
                user_name: action.payload.nombre,
                user_surname: action.payload.apellido,
                user_document: action.payload.documento,
                user_email: action.payload.email,
                user_phone: action.payload.telefono,
                user_version: action.payload.version
            };
        case userConstants.CHANGE_PASSWORD_FAILURE:
            return { ...state, loading_user: false };
        default:
            return state;
    }
};
