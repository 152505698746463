import theme from "./palette.jsx";

const checkmarkAnimation = {
    wrapper: {
        margin: "0 auto"
    },
    voucher: {
        lineHeight: "1.2",
        margin: "1em auto",
        display: "block",
        position: "relative",
        left: "28%"
    },
    link: {
        color: theme.palette.primary.main
    }
};

export default checkmarkAnimation;
