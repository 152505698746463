import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import withStyles from "@material-ui/core/styles/withStyles";
import appStyles from "../../assets/css/appStyles.jsx";

import Login from "../Auth/Login";
import SignUp from "../Auth/SignUp";
import PaymentForm from "../Payment/PaymentForm";
import Home from "../Home";
import Alert from "../../components/Alert";
import UserComponent from "../User";

import { Router, Route, Redirect, Switch } from "react-router-dom";
import { PrivateRoute } from "../../components/PrivateRoute/PrivateRoute";
import { history } from "../../_helpers/history";
import { logout, setUserFromLocalStorage } from "../User/actions";
import { clearMessage } from "./actions";
import axios from "../../utilities/axiosSetUp";
import ReactGA from "react-ga";
import { DEFAULT_GA_CONFIG } from "../../config";
import _ from "lodash";

import {
    LinearProgress,
    AppBar,
    Toolbar,
    Typography,
    IconButton,
    Menu,
    MenuItem
} from "@material-ui/core";
import { Link } from "react-router-dom";
import AccountCircle from "@material-ui/icons/AccountCircle";
import logo from "../../assets/img/hidroven.png";

ReactGA.initialize([DEFAULT_GA_CONFIG]);
history.listen((location, action) => {
    ReactGA.set({ page: location.pathname });
    ReactGA.pageview(location.pathname);
});

class App extends Component {
    responseInterceptor;

    constructor(props) {
        super(props);
        this.state = {
            anchorEl: null
        };
    }

    componentDidMount() {
        const user_id = JSON.parse(localStorage.getItem("user"));
        const token = JSON.parse(localStorage.getItem("token"));
        this.props.dispatch(setUserFromLocalStorage(user_id, token));

        const responseInterceptorWrapper = () => {
            this.responseInterceptor = (response) => {
                const user = JSON.parse(localStorage.getItem("user"));
                if (
                    _.includes(response.request.responseURL, "refresh") ||
                    _.includes(response.request.responseURL, "login")
                ) {
                    return response;
                }
                axios.put(`usuarios/${user}/refresh/`, { timeout: 15000 });
                return response;
            };

            return this.responseInterceptor;
        };

        axios.interceptors.response.use(responseInterceptorWrapper(), (error) => {
            if (
                error.response &&
                (error.response.status === 401 || error.response.status === 403)
            ) {
                this.props.dispatch(logout());
            }
            return;
        });
    }

    componentWillUnmount() {
        this.removeInterceptor();
    }

    removeInterceptor = () => {
        axios.interceptors.response.eject(this.responseInterceptor);
    };

    handleMenuClose = () => {
        this.setState({ anchorEl: null });
    };

    handleLogOut = () => {
        this.handleMenuClose();
        this.props.dispatch(logout());
    };

    handleProfileMenuOpen = (event) => {
        this.setState({ anchorEl: event.currentTarget });
    };

    handleClickMenuPath = (path) => {
        history.push(path);
        this.handleMenuClose();
    };

    clearAlertMessage = () => {
        this.props.dispatch(clearMessage());
    };

    render() {
        const { classes, message, is_loading, user_id, error_code } = this.props;
        const { anchorEl } = this.state;

        const isMenuOpen = Boolean(anchorEl);

        const renderMenu = (
            <Menu
                anchorEl={anchorEl}
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                transformOrigin={{ vertical: "top", horizontal: "right" }}
                open={isMenuOpen}
                onClose={this.handleMenuClose}
            >
                {history.location.pathname !== "/user" && (
                    <MenuItem onClick={() => this.handleClickMenuPath("/user")}>Perfil</MenuItem>
                )}
                {history.location.pathname !== "/" && (
                    <MenuItem onClick={() => this.handleClickMenuPath("/")}>Contratos</MenuItem>
                )}
                <MenuItem onClick={this.handleLogOut}>Cerrar sesión</MenuItem>
            </Menu>
        );

        return (
            <Router history={history}>
                <div className={classes.app}>
                    {user_id && (
                        <AppBar position="static">
                            <Toolbar className={classes.appbar}>
                                <Link to="/">
                                    <img src={logo} alt="Logo" className={classes.logo} />
                                </Link>
                                <Typography variant="headline" color="inherit">
                                    Hidroweb
                                </Typography>
                                <div className={classes.grow} />
                                <div className={classes.sectionDesktop}>
                                    <IconButton
                                        aria-owns={isMenuOpen ? "material-appbar" : undefined}
                                        aria-haspopup="true"
                                        onClick={this.handleProfileMenuOpen}
                                        color="inherit"
                                    >
                                        <AccountCircle />
                                    </IconButton>
                                </div>
                            </Toolbar>
                        </AppBar>
                    )}
                    <div className={classes.linear_progress}>
                        {is_loading && <LinearProgress />}
                    </div>
                    {renderMenu}
                    <Switch>
                        <PrivateRoute
                            exact
                            path="/"
                            component={Home}
                            location={this.props.location}
                        />
                        <Route path="/login" component={Login} />
                        <Route path="/register" component={SignUp} />
                        <PrivateRoute exact path="/payment" component={PaymentForm} />
                        <PrivateRoute exact path="/user" component={UserComponent} />
                        <Redirect to="/" />
                    </Switch>
                    {error_code !== 403 && error_code !== 401 && (
                        <Alert message={message} clearMessage={this.clearAlertMessage} />
                    )}
                </div>
            </Router>
        );
    }
}

App.propTypes = {
    message: PropTypes.string,
    is_loading: PropTypes.bool,
    error_code: PropTypes.number,
    user_id: PropTypes.string
};

const mapStateToProps = (state) => {
    const { message, is_loading, error_code } = state.appReducers;
    const { user_id } = state.userReducers;
    return { message, is_loading, user_id, error_code };
};

export default connect(mapStateToProps)(withStyles(appStyles)(App));
