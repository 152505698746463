import theme from "./palette.jsx";

const contractStyles = {
    container: {
        backgroundColor: theme.palette.background.paper
    },
    contract_img: {
        objectFit: "scale-down",
        width: "4rem",
        height: "4rem"
    },
    empty_list: {
        textAlign: "center"
    },
    empty_list_text: {
        color: "rgb(147, 154, 165)"
    },
    detail_container: {
        padding: theme.spacing.unit * 4,
        textAlign: "left"
    },
    hydro_container: {
        marginTop: 55
    },
    hydro_item: {
        objectFit: "scale-down",
        width: "6rem",
        height: "6rem"
    },
    hydro_button: {
        width: "100%",
        justifyContent: "center"
    },
    hydro_disabled_label: {
        color: "rgba(0, 0, 0, 0.38)"
    },
    empty_details: {
        marginTop: "20%"
    },
    list_item: {
        cursor: "pointer",
        "&:hover": {
            textDecoration: "none",
            backgroundColor: "rgba(0, 0, 0, 0.08)"
        }
    }
};

export default contractStyles;
