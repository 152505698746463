export const paymentsConstants = {
    GET_PAYMENTS_REQUEST: "GET_PAYMENTS_REQUEST",
    GET_PAYMENTS_SUCCESS: "GET_PAYMENTS_SUCCESS",
    GET_PAYMENTS_FAILURE: "GET_PAYMENTS_FAILURE",
    POST_PAYMENT_REQUEST: "POST_PAYMENT_REQUEST",
    POST_PAYMENT_SUCCESS: "POST_PAYMENT_SUCCESS",
    POST_PAYMENT_FAILURE: "POST_PAYMENT_FAILURE",
    POST_PAYMENT_SERVER_FAILURE: "POST_PAYMENT_SERVER_FAILURE",
    CLEAR_PAYMENT_DATA: "CLEAR_PAYMENT_DATA",
    RESET_PAYMENTS_LIST: "RESET_PAYMENTS_LIST"
};
