import "./index.css";

import React from "react";
import { render } from "react-dom";

import App from "./containers/App";

import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { Provider } from "react-redux";
import rootReducer from "./reducers";
import { saveUser } from "./utilities/localStorage";

import JssProvider from "react-jss/lib/JssProvider";
import { createGenerateClassName } from "@material-ui/core/styles";

const generateClassName = createGenerateClassName({
    dangerouslyUseGlobalCSS: false,
    productionPrefix: "c"
});

const store = createStore(
    rootReducer,
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__(),
    applyMiddleware(thunk)
);

store.subscribe(() => {
    const user_id = store.getState().userReducers.user_id;
    if (user_id) {
        saveUser(user_id, store.getState().userReducers.token);
    }
});

render(
    <JssProvider generateClassName={generateClassName}>
        <Provider store={store}>
            <App />
        </Provider>
    </JssProvider>,
    document.querySelector("#app")
);
