import React, { Fragment } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { getIntakeList } from "./actions";

import withStyles from "@material-ui/core/styles/withStyles";
import billsStyles from "../../assets/css/billsStyles.jsx";

import { CircularProgress, Typography } from "@material-ui/core";
import { Chart } from "react-charts";

class Intake extends React.Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        const { selected_contract_uuid, chart_data, getIntakeList } = this.props;
        if (!chart_data.length) {
            getIntakeList(selected_contract_uuid);
        }
    }

    componentDidUpdate(prevProps) {
        const { selected_contract_uuid, getIntakeList } = this.props;
        if (prevProps.selected_contract_uuid !== this.props.selected_contract_uuid) {
            getIntakeList(selected_contract_uuid);
        }
    }

    render() {
        const { classes, chart_data, loading_intake, chart_max_value } = this.props;

        if (loading_intake) {
            return <CircularProgress className={classes.empty_details} />;
        }
        return (
            <Fragment>
                <div className={classes.chart_styles}>
                    {chart_data.length > 0 && (
                        <Chart
                            width="200"
                            height="100"
                            data={[{ label: "Consumo facturado", data: chart_data }]}
                            series={{ type: "bar" }}
                            axes={[
                                { primary: true, type: "ordinal", position: "bottom" },
                                {
                                    type: "linear",
                                    position: "left",
                                    hardMin: 0,
                                    hardMax: chart_max_value,
                                    stacked: true
                                }
                            ]}
                            tooltip
                        />
                    )}
                    {chart_data.length === 0 && (
                        <div className={classes.empty_details}>
                            <Typography variant="subheading">
                                No se encuentran datos de consumo asociados al contrato
                                seleccionado.
                            </Typography>
                        </div>
                    )}
                </div>
            </Fragment>
        );
    }
}

Intake.propTypes = {
    chart_data: PropTypes.array,
    loading_intake: PropTypes.bool.isRequired,
    chart_max_value: PropTypes.number.isRequired
};

const mapStateToProps = (state) => {
    const { chart_data, loading_intake, chart_max_value } = state.intakeReducers;
    const { selected_contract_uuid } = state.contractsReducers;
    return { chart_data, loading_intake, chart_max_value, selected_contract_uuid };
};

const mapDispatchToProps = {
    getIntakeList
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(billsStyles)(Intake));
